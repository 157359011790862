import { useState, useEffect } from 'react';

import { useSocket } from 'context';
import { ButtonColors } from 'types';
import { joinStrings } from 'utils/helpers';
import { isMobile, useAlert, isDesktop } from 'hooks';
import { useLazyGenerateReportPdfQuery } from 'store/api/event';
import { Icon, Flex, Button, ButtonProps, CloseButton } from 'components';

import { EventHeaderProps } from './types';

export const EventHeader = ({
  date,
  title,
  event,
  onClose,
  isDailyReport,
  isEmptyReport,
}: EventHeaderProps) => {
  const mobile = isMobile();
  const desktop = isDesktop();
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [pdfJobId, setPdfJobId] = useState<string>();

  const [trigger, { isLoading }] = useLazyGenerateReportPdfQuery();

  const { showErrorAlert } = useAlert();
  const { subscribe, isConnected } = useSocket();

  const headerButtons: ButtonProps[] = [
    { disabled: true, text: 'Add notes', icon: <Icon.Edit /> },
    { disabled: true, text: 'Export', icon: <Icon.Share /> },
    {
      text: 'Save PDF',
      icon: <Icon.Download />,
      disabled: isLoading || !!pdfJobId || isEmptyReport,
      onClick: async () => {
        if (pdfUrl) {
          window.open(pdfUrl, '_blank');
        } else {
          if (isConnected()) {
            const response = await trigger({
              isDailyReport,
              id: String(event.id),
            }).unwrap();
            setPdfJobId(response.jobId);
          } else {
            showErrorAlert('You have unstable network connection or offline');
          }
        }
      },
    },
  ];

  useEffect(() => {
    if (!pdfJobId) {
      return;
    }

    const timeoutId = setTimeout(() => {
      showErrorAlert('Download request timed out after 30 seconds');
      setPdfJobId(undefined);
    }, 30000);

    const unsubscribe = subscribe('pdf-url', (data) => {
      if (data.jobId === pdfJobId) {
        setPdfUrl(data.url);
        setPdfJobId(undefined);
        window.open(data.url, '_blank');
      }
    });

    return () => {
      unsubscribe();
      clearTimeout(timeoutId);
    };
  }, [pdfJobId, subscribe, showErrorAlert]);

  return (
    <Flex
      gap={24}
      justifyContent="space-between"
      flexDirection={desktop ? 'row' : 'column'}
    >
      <Flex
        gap={mobile ? 8 : 24}
        flexDirection={mobile ? 'column' : 'row'}
        alignItems={mobile ? undefined : 'center'}
      >
        <h2>{title}</h2>
        <Flex flexDirection="column">
          <p>Date</p>
          <h3>{date}</h3>
        </Flex>
        <Flex flexDirection="column">
          <p>Location</p>
          <h3>
            {joinStrings(
              event.locations.map((value) => value.name),
              ', '
            )}
          </h3>
        </Flex>
      </Flex>

      <Flex gap={16}>
        {headerButtons.map((button, i) => (
          <Button
            key={i}
            transparent
            padding="0"
            colorType={ButtonColors.LightBlue}
            {...button}
            text={mobile ? undefined : button.text}
            tooltip={mobile ? button.text : undefined}
          />
        ))}
        {onClose && <CloseButton onClose={onClose} />}
      </Flex>
    </Flex>
  );
};
