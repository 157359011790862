import styled, { css } from 'styled-components';

import { captionSemibold } from 'styles/mixins/fonts';

export const Container = styled.div<{ width: string }>`
  height: inherit;

  ${({ width }) =>
    width &&
    css`
      min-width: ${width};
    `}
`;

export const Tooltip = styled.div`
  ${captionSemibold};

  padding: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    color: ${theme.config.barChart.tooltip.color};
    background-color: ${theme.config.barChart.tooltip.bg};
  `}
`;

export const Text = styled.text`
  font-size: 12px;
  fill: ${({ theme }) => theme.config.text.primary};
`;
