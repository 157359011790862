export const employeeConfig = {
  template: '2fr repeat(3, 1fr)',
  titles: ['Name', 'Email', 'Phone number', 'Role'],
  cellsMobile: [
    { row: '1/2', col: '1/3' },
    { row: '2/3', col: '1/3' },
    { row: '3/4', col: '1/2' },
    { row: '3/4', col: '2/3' },
  ],
};

export const commissionTitles = [
  'Date',
  'Gross',
  'Net',
  'Commissions',
  'Tips',
  'Bonuses',
  'Advances',
];
