import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { addParamsToUrl } from 'utils/query';
import { calculateOffset } from 'utils/helpers';
import { AccountingExpense, AccountingCategory } from 'types';

import { payoutApi, tagTypes as payoutTagTypes } from '../payout';
import {
  commissionLevelApi,
  tagTypes as commissionLevelTagTypes,
} from '../commission-level';

import {
  SaleTax,
  MonthlyTax,
  Chargeback,
  Transaction,
  ProfitAndLoss,
  SaleTaxRequest,
  AccountingSale,
  ExpensesRequest,
  UpdateMonthlyTax,
  MonthlyTaxRequest,
  ChargebacksRequest,
  TransactionRequest,
  EditExpensesRequest,
  EditCategoryRequest,
  CreateExpenseRequest,
  CreateCategoryRequest,
  AccountingSaleRequest,
  ProfitAndLossesRequest,
  ChargebackResponseData,
  UpdateChargebackRequest,
  TransactionResponseData,
  ChangeChargebackStatusRequest,
} from './types';

const tagTypes = ['Accounting'];

const invalidatePayoutAndCommissionLevelTags = async <T>(
  args: T,
  { dispatch, queryFulfilled }: any
) => {
  try {
    await queryFulfilled;
    dispatch(payoutApi.util.invalidateTags(payoutTagTypes));
    dispatch(commissionLevelApi.util.invalidateTags(commissionLevelTagTypes));
  } catch (error) {
    console.error(error);
  }
};

export const accountingApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'accountingApi',
  endpoints: (build) => ({
    categories: build.query<AccountingCategory[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_CATEGORIES,
      }),
    }),

    deleteExpense: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: generatePath(URL.GET_ACCOUNTING_EXPENSE, { id }),
      }),
    }),

    getExpense: build.query<AccountingExpense, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: generatePath(URL.GET_ACCOUNTING_EXPENSE, { id }),
      }),
    }),

    deleteCategory: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: generatePath(URL.GET_ACCOUNTING_CATEGORY, { id }),
      }),
    }),

    getCategory: build.query<AccountingCategory, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: generatePath(URL.GET_ACCOUNTING_CATEGORY, { id }),
      }),
    }),

    taxes: build.query<SaleTax[], SaleTaxRequest>({
      providesTags: tagTypes,
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.GET_ACCOUNTING_SALES_TAX, params),
      }),
    }),

    monthlyTaxes: build.query<MonthlyTax[], MonthlyTaxRequest>({
      providesTags: tagTypes,
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.GET_MONTHLY_TAXES, params),
      }),
    }),

    expenses: build.query<AccountingExpense[], ExpensesRequest>({
      providesTags: tagTypes,
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.GET_ACCOUNTING_EXPENSES, params),
      }),
    }),

    createExpense: build.mutation<AccountingExpense, CreateExpenseRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.CREATE_ACCOUNTING_EXPENSE,
      }),
    }),

    createCategory: build.mutation<AccountingCategory, CreateCategoryRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.CREATE_ACCOUNTING_CATEGORY,
      }),
    }),

    profitAndLosses: build.query<ProfitAndLoss[], ProfitAndLossesRequest>({
      providesTags: tagTypes,
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.GET_ACCOUNTING_PROFIT_LOSSES, params),
      }),
    }),

    sales: build.query<AccountingSale, AccountingSaleRequest>({
      providesTags: tagTypes,
      query: ({ endDate, startDate }) => ({
        method: 'get',
        url: addParamsToUrl(URL.GET_ACCOUNTING_SALES, { endDate, startDate }),
      }),
    }),

    updateChargeback: build.mutation<Chargeback, UpdateChargebackRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generatePath(URL.UPDATE_CHARGEBACK, { id }),
      }),
    }),

    editExpense: build.mutation<AccountingExpense, EditExpensesRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generatePath(URL.GET_ACCOUNTING_EXPENSE, { id }),
      }),
    }),

    editCategory: build.mutation<AccountingCategory, EditCategoryRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generatePath(URL.GET_ACCOUNTING_CATEGORY, { id }),
      }),
    }),

    updateMonthlyTax: build.mutation<MonthlyTax, UpdateMonthlyTax>({
      invalidatesTags: tagTypes,
      query: ({ year, month, ...data }) => ({
        data,
        method: 'post',
        url: generatePath(URL.UPDATE_MONTHLY_TAX, { year, month }),
      }),
    }),

    markDisputed: build.mutation<Chargeback, number>({
      invalidatesTags: tagTypes,
      onQueryStarted: invalidatePayoutAndCommissionLevelTags<number>,
      query: (id) => ({
        method: 'post',
        url: generatePath(URL.ACCOUNTING_MARK_DISPUTE, { id }),
      }),
    }),

    changeChargebackStatus: build.mutation<
      Chargeback,
      ChangeChargebackStatusRequest
    >({
      invalidatesTags: tagTypes,
      onQueryStarted:
        invalidatePayoutAndCommissionLevelTags<ChangeChargebackStatusRequest>,
      query: ({ id, status }) => ({
        method: 'patch',
        data: { status },
        url: generatePath(URL.UPDATE_CHARGEBACK_STATUS, { id }),
      }),
    }),

    chargebacks: build.query<ChargebackResponseData, ChargebacksRequest>({
      providesTags: tagTypes,
      query: (params) => {
        const { page = 1, limit = 20, ...otherParams } = params || {};
        const offset = calculateOffset(page, limit);
        const searchParams = {
          limit,
          offset,
          ...otherParams,
        };

        return {
          method: 'get',
          url: addParamsToUrl(URL.GET_CHARGEBACKS, searchParams),
        };
      },
    }),

    transactions: build.query<TransactionResponseData, TransactionRequest>({
      providesTags: tagTypes,
      query: (params) => {
        const { page = 1, limit = 20, ...otherParams } = params || {};
        const offset = calculateOffset(page, limit);
        const searchParams = {
          limit,
          offset,
          ...otherParams,
        };

        return {
          method: 'get',
          url: addParamsToUrl(URL.GET_ACCOUNTING_TRANSACTIONS, searchParams),
        };
      },
    }),
  }),
});

export const {
  useTaxesQuery,
  useSalesQuery,
  useExpensesQuery,
  useGetExpenseQuery,
  useCategoriesQuery,
  useGetCategoryQuery,
  useChargebacksQuery,
  useTransactionsQuery,
  useMonthlyTaxesQuery,
  useEditExpenseMutation,
  useMarkDisputedMutation,
  useEditCategoryMutation,
  useProfitAndLossesQuery,
  useCreateExpenseMutation,
  useDeleteExpenseMutation,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateMonthlyTaxMutation,
  useUpdateChargebackMutation,
  useChangeChargebackStatusMutation,
} = accountingApi;

export type {
  SaleTax,
  MonthlyTax,
  Chargeback,
  Transaction,
  ProfitAndLoss,
  TransactionRequest,
  TransactionResponseData,
  UpdateChargebackRequest,
};
