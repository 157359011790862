import { useShowMore } from 'hooks';
import { ButtonColors } from 'types';
import { formatPrice } from 'utils/helpers';
import { formatTimezoneSpecific } from 'utils/date';
import {
  Icon,
  Flex,
  List,
  Popup,
  ListCard,
  ListHeader,
  ButtonWithRef,
} from 'components';

import * as CommonStyles from '../styles';

import * as Styles from './styles';
import { RevenueProps, PopupListProps } from './types';

const tabletTemplate = '1fr 1fr';
const template = 'repeat(9, 1fr) 64px';
const employeeTitles = ['Name', 'Gross Sales'];
const usersTitles = ['Name', 'Created check out'];
const titles = [
  'Dates',
  'Check',
  'Cash',
  'Net Credit',
  'Tip',
  'Taxes',
  'Fees',
  'Shipping',
  'Total',
  '',
];

const commonButtonProps = {
  padding: '0',
  transparent: true,
  colorType: ButtonColors.BlackWhite,
};

const PopupList = ({
  count,
  trigger,
  elements,
  headerTitles,
}: PopupListProps) => (
  <Popup
    trigger={trigger}
    position={[
      'top left',
      'top right',
      'top center',
      'bottom left',
      'bottom right',
      'bottom center',
    ]}
  >
    <Styles.RevenueContainer>
      <CommonStyles.StyledList
        count={count}
        mainWrapperStyles={{ padding: 8 }}
        header={<ListHeader padding="8px" titles={headerTitles} />}
      >
        {elements}
      </CommonStyles.StyledList>
    </Styles.RevenueContainer>
  </Popup>
);

export const Revenue = ({ data, event }: RevenueProps) => {
  const { visibleData, ...showMoreProps } = useShowMore(data);

  return (
    <List
      {...showMoreProps}
      count={visibleData?.length}
      mainWrapperStyles={{ padding: 0 }}
      header={<ListHeader padding="8px" titles={titles} template={template} />}
    >
      {visibleData?.map((value, index) => (
        <ListCard
          key={index}
          headers={titles}
          template={template}
          values={[
            formatTimezoneSpecific(value.checkoutDate, event?.timezone?.code),
            formatPrice(value.paymentMethods.check),
            formatPrice(value.paymentMethods.cash),
            formatPrice(value.paymentMethods.credit),
            formatPrice(value.total.tip),
            formatPrice(value.total.tax),
            formatPrice(value.total.processingFees),
            formatPrice(value.total.shippingPrices),
            formatPrice(value.total.total),
            <Flex gap={32}>
              <PopupList
                headerTitles={employeeTitles}
                count={value.employees.length}
                trigger={(open) => (
                  <ButtonWithRef
                    {...commonButtonProps}
                    icon={open ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
                  />
                )}
                elements={value.employees.map((employee, i) => (
                  <ListCard
                    key={i}
                    headers={employeeTitles}
                    tabletTemplate={tabletTemplate}
                    values={[employee.name, formatPrice(employee.grossSales)]}
                  />
                ))}
              />

              <PopupList
                headerTitles={usersTitles}
                count={value.users.length}
                trigger={
                  <ButtonWithRef {...commonButtonProps} icon={<Icon.Info />} />
                }
                elements={value.users.map((user) => (
                  <ListCard
                    key={user.userId}
                    headers={usersTitles}
                    tabletTemplate={tabletTemplate}
                    values={[user.name, user.createdCheckout]}
                  />
                ))}
              />
            </Flex>,
          ]}
        />
      ))}
    </List>
  );
};
