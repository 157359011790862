import { Messages } from 'config';
import { PayoutType } from 'types';
import { mapQuery } from 'utils/query';
import { formatDate } from 'utils/date';
import { formatPrice } from 'utils/helpers';
import { useFilter, handleMutation, useSearchQuery } from 'hooks';
import { List, ListCard, ListHeader, DeleteDialog } from 'components';
import { Payout, useListQuery, useDeleteMutation } from 'store/api/payout';

import { CashFlowProps } from './types';
import { CreateBonusAdvanceDialog } from './create-bonus-advance-dialog';

const footerTabletTemplate = '1fr 1fr';
const template = 'repeat(5, 1fr) 24px';
const titles = ['Date', 'Amount', 'Type', 'Employee', 'Notes', ''];
const paths = [
  'date',
  'type',
  'notes',
  'amount',
  'event.name',
  'employee.user.name',
];

export const CashFlow = ({ event }: CashFlowProps) => {
  const { debounceQuery, ...search } = useSearchQuery();
  const { data, ...props } = useListQuery({
    eventId: String(event.id),
    types: JSON.stringify([PayoutType.PrepaidBonus, PayoutType.PrepaidAdvance]),
  });

  const currentData = [...(data?.bonuses || []), ...(data?.advances || [])];

  const [remove, mutation] = useDeleteMutation();
  handleMutation({
    ...mutation,
    successMessage: `Expense ${Messages.DELETED_SUCCESSFULLY}`,
  });

  const filteredValues = useFilter<Payout>({
    paths,
    query: debounceQuery,
    data: currentData.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    ),
  });

  return (
    <List
      {...mapQuery(props)}
      {...search}
      count={filteredValues.length}
      mainWrapperStyles={{ padding: 0 }}
      searchContainerStyle={{ margin: '0 0 16px 0' }}
      header={<ListHeader padding="8px" titles={titles} template={template} />}
      searchBarRightSlot={
        <CreateBonusAdvanceDialog
          eventId={event.id}
          isOnlyCashLocation
          customTitle="Add bonus or advance"
        />
      }
      footer={
        <ListCard
          selected
          padding="16px"
          template={template}
          tabletTemplate={footerTabletTemplate}
          mobileTemplate={footerTabletTemplate}
          values={[
            'Total',
            formatPrice(
              currentData?.reduce((acc, value) => acc + +value.amount, 0)
            ),
          ]}
        />
      }
    >
      {filteredValues.map((value) => (
        <ListCard
          key={value.id}
          headers={titles}
          template={template}
          values={[
            formatDate(value.date),
            formatPrice(value.amount),
            value.type,
            value.employee?.user?.name,
            value.notes,
            <DeleteDialog
              id={value?.id}
              action={remove}
              title={`Delete ${value.type}`}
              name={`${formatPrice(value?.amount)}`}
            />,
          ]}
        />
      ))}
    </List>
  );
};
