import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';

import { apiQuery } from '../../query';

import { Cart, CartAddRequest, CartRemoveRequest } from './types';

export const tagTypes = ['Cart'];

export const cartApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'cartApi',
  endpoints: (build) => ({
    details: build.query<Cart, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.CART.replace(':id', id),
      }),
    }),

    reset: build.mutation<Cart, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'post',
        url: URL.CART_RESET.replace(':id', id),
      }),
    }),

    add: build.mutation<Cart, CartAddRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: URL.CART_ADD.replace(':id', String(id)),
      }),
    }),

    delete: build.mutation<Cart, CartRemoveRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, cartId }) => ({
        method: 'delete',
        url: URL.CART_REMOVE_ITEM.replace(':id', String(id)).replace(
          ':cartId',
          String(cartId)
        ),
      }),
    }),
  }),
});

export type { Cart };
export const {
  useAddMutation,
  useDetailsQuery,
  useResetMutation,
  useDeleteMutation,
} = cartApi;
