import { shift } from '@floating-ui/dom';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useRef, useMemo, useState, useEffect } from 'react';

import { isMobile } from 'hooks';
import { Flex } from 'components/flex';
import { InputWithRef } from 'components/input';
import { EllipsisText } from 'components/ellipsis-text';

import Footer from './footer';
import Sidebar from './sidebar';
import { Header } from './header';
import * as Styles from './styles';
import { DateInputProps } from './types';
import { PopperContainer } from './styles';

export const DatePicker = ({
  name,
  label,
  input,
  title,
  inline,
  endDate,
  selected,
  disabled,
  onChange,
  className,
  startDate,
  pickerRef,
  monthsShown,
  customInput,
  selectsRange,
  changeByButton,
  additionalRanges,
  dateFormat = 'MM/dd/yyyy',
  ...props
}: DateInputProps) => {
  const mobile = isMobile();

  const [currentStartDate, setCurrentStartDate] = useState(
    selectsRange ? startDate : selected
  );
  const [currentEndDate, setCurrentEndDate] = useState(endDate);
  const calendarRef = useRef<ReactDatePicker>(null);

  useEffect(() => {
    if (pickerRef) {
      pickerRef.current = calendarRef.current;
    }
  }, [pickerRef, calendarRef.current]);

  const setCurrentDate = (date: any) => {
    if (selectsRange) {
      const [start, end] = date;
      setCurrentStartDate(start);
      setCurrentEndDate(end);
    } else {
      setCurrentStartDate(date);
    }
  };

  useEffect(() => {
    if (selectsRange) {
      setCurrentDate([startDate, endDate]);
    } else {
      setCurrentDate(selected);
    }
  }, [startDate, endDate, selected, selectsRange]);

  const handleSidebarRange = (date: Date) => {
    // @ts-ignore
    calendarRef?.current?.setPreSelection(date);
  };

  const closePicker = () => {
    calendarRef?.current?.setOpen(false);
  };

  const onSave = () => {
    if (selectsRange) {
      setCurrentDate([currentStartDate, currentEndDate]);
    } else {
      setCurrentDate(currentStartDate);
    }
    closePicker();
  };

  const onCancel = () => {
    const dates = selectsRange ? [startDate, endDate] : selected;
    setCurrentDate(dates);
    onChange(dates);
    closePicker();
  };

  const sidebar = changeByButton && selectsRange && (
    <Sidebar
      additionalRanges={additionalRanges}
      onSelect={(range) => {
        handleSidebarRange(range[0]);
        setCurrentDate(range);
      }}
    />
  );

  const popperModifiers = useMemo(() => [shift({ padding: 16 })], []);

  return (
    <PopperContainer
      title={title}
      inline={inline}
      className={className}
      changeByButton={changeByButton}
      isYearPicker={props.showYearPicker}
    >
      <ReactDatePicker
        inline={inline}
        ref={calendarRef}
        id={name ?? label}
        autoComplete="off"
        disabled={disabled}
        dateFormat={dateFormat}
        showPopperArrow={false}
        disabledKeyboardNavigation
        withPortal={mobile && !inline}
        popperModifiers={popperModifiers}
        shouldCloseOnSelect={!changeByButton}
        monthsShown={mobile ? 1 : monthsShown}
        endDate={changeByButton ? currentEndDate : endDate}
        onClickOutside={changeByButton ? onCancel : undefined}
        startDate={changeByButton ? currentStartDate : startDate}
        selected={
          changeByButton
            ? currentStartDate
            : selectsRange
              ? startDate
              : selected
        }
        renderCustomHeader={
          props.showMonthYearPicker
            ? undefined
            : (headerProps) => (
                <Flex column gap={16}>
                  {mobile && sidebar}
                  <Header {...headerProps} />
                </Flex>
              )
        }
        customInput={
          customInput || (
            <InputWithRef
              label={label}
              autofill="off"
              rightIcon={<Styles.Calendar />}
              onRightIconClick={() => {
                const calendar = calendarRef.current;
                if (!calendar) {
                  return;
                }
                calendar.setOpen(!calendar.isCalendarOpen());
              }}
              {...input}
            />
          )
        }
        {...(selectsRange
          ? {
              selectsRange: true,
              onChange: (range) => {
                setCurrentDate(range);
                onChange(range);
              },
            }
          : { onChange: (date) => onChange(date) })}
        {...props}
      >
        {title && (
          <EllipsisText>
            <Styles.Title>{title}</Styles.Title>
          </EllipsisText>
        )}

        {!mobile && sidebar}

        {changeByButton && (
          <Footer
            onSave={onSave}
            onCancel={onCancel}
            monthsShown={monthsShown}
          />
        )}
      </ReactDatePicker>
    </PopperContainer>
  );
};

export type { DateInputProps };
export { PopperContainer as DatePickerPopperContainer };
