import { Formik, FormikValues } from 'formik';

import { ButtonColors } from 'types';
import { useAppSelector } from 'store';
import { formatDate } from 'utils/date';
import { isMobile, useNavigate } from 'hooks';
import { useListQuery } from 'store/api/event';
import { selectAuthUser } from 'store/slices/auth';
import {
  Flex,
  Button,
  BackButton,
  FormFooter,
  ButtonTypes,
  CommonStyles,
} from 'components';

import * as Styles from './styles';
import { PaymentsFormProps } from './types';
import { PaymentsFormFields } from './fields';

export const PaymentsForm = function <T extends FormikValues>({
  title,
  isEdit,
  onSubmit,
  children,
  isTopChildren,
  initialValues,
  footerButtons,
  hideBackButton,
  validationSchema,
  ...commonProps
}: PaymentsFormProps<T>) {
  const mobile = isMobile();
  const navigate = useNavigate();
  const authUser = useAppSelector(selectAuthUser);

  const { isFetching, data: eventData } = useListQuery({ limit: 10000 });

  const eventOptions =
    eventData?.rows?.map((value) => ({ value: value.id, label: value.name })) ||
    [];

  const getIsEventTeamLead = (eventId: number) => {
    const selectedEvent = eventData?.rows?.find(
      (event) => event.id === eventId
    );
    return selectedEvent?.leaderId === authUser?.id;
  };

  return (
    <Flex fullWidth fullHeight flexDirection="column">
      {!hideBackButton && (
        <BackButton text={`${isEdit ? 'Edit' : 'New'} ${title}`} />
      )}
      <Formik<T>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, helpers) =>
          onSubmit(
            {
              ...values,
              date: formatDate(values.date),
              isTeamLead: getIsEventTeamLead(values.eventId),
            },
            helpers
          )
        }
      >
        {(props) => {
          const childrenProps =
            typeof children === 'function' ? children(props) : children;
          return (
            <CommonStyles.Form $hasMinHeight>
              <Styles.Container>
                {isTopChildren && childrenProps}
                <PaymentsFormFields
                  {...commonProps}
                  isFetching={isFetching}
                  eventOptions={eventOptions}
                />

                {!isTopChildren && childrenProps}
              </Styles.Container>

              {footerButtons ? (
                footerButtons(props)
              ) : (
                <FormFooter
                  right={
                    <CommonStyles.FormButtons>
                      <Button
                        text="Cancel"
                        fullWidth={mobile}
                        onClick={() => navigate(-1)}
                        colorType={ButtonColors.LightBlue}
                      />
                      <Button
                        fullWidth={mobile}
                        type={ButtonTypes.Submit}
                        text={isEdit ? 'Edit' : 'Create'}
                        disabled={!props.dirty || props.isSubmitting}
                      />
                    </CommonStyles.FormButtons>
                  }
                />
              )}
            </CommonStyles.Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export { PaymentsFormFields };
