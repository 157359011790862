import { AppRoutes } from 'config';
import { useHeader } from 'context';
import { mapQuery } from 'utils/query';
import { useSearchPagination } from 'hooks';
import { useListQuery } from 'store/api/users';
import { List, ListHeader, NewEntityButton } from 'components';

import { Card } from './card';
import { titles } from './config';

export const ListPage = () => {
  const pageData = useSearchPagination();
  const { currentPage: page, debounceQuery: search } = pageData;
  const { data, ...props } = useListQuery({ page, search });

  useHeader({ pageName: 'Users' });

  return (
    <List
      {...mapQuery(props)}
      {...pageData}
      count={data?.count}
      header={<ListHeader titles={titles} />}
      mainWrapperStyles={{ paddingBottom: '16px' }}
      searchBarRightSlot={
        <NewEntityButton text="New User" path={AppRoutes.Users.New} />
      }
    >
      {data?.rows?.map((item) => <Card data={item} key={item.id} />)}
    </List>
  );
};
