import { useParams } from 'react-router-dom';

import { useHeader } from 'context';
import { mapQuery } from 'utils/query';
import { useDetailsQuery } from 'store/api/stock-transfers';
import { StockTransferDetails, Details as DetailsComponent } from 'components';

const Details = () => {
  const { id } = useParams();
  const { data, ...query } = useDetailsQuery(id!, { skip: !id });

  useHeader({
    showBackButton: true,
    pageName: 'Stock transfer',
  });

  if (!data) {
    return null;
  }

  return (
    <DetailsComponent {...mapQuery(query)}>
      {data && <StockTransferDetails data={data} />}
    </DetailsComponent>
  );
};

export default Details;
