import { useNavigate } from 'hooks';
import { Flex } from 'components/flex';
import { List } from 'components/list';
import { formatDate } from 'utils/date';
import { Avatar } from 'components/avatar';
import { formatPrice } from 'utils/helpers';
import { Messages, AppRoutes } from 'config';
import { ListCard } from 'components/list-card';
import { ListHeader } from 'components/list-header';
import { DeleteDialog } from 'components/delete-dialog';
import {
  useRemoveMutation,
  StockTransferStatus,
} from 'store/api/stock-transfers';

import * as Styles from './styles';
import { StockTransferDetailsProps } from './types';

const titles = ['Name', 'Count', 'Price', 'Total price'];

export const StockTransferDetails = ({
  data,
  isOnlyRead,
}: StockTransferDetailsProps) => {
  const navigate = useNavigate();
  const [remove, mutation] = useRemoveMutation();

  const isCompletedOrOnlyRead =
    isOnlyRead || data.status === StockTransferStatus.Completed;

  return (
    <Styles.StyledGeneralInfo
      withoutPhoto
      withPlaceholders
      stickyWithHeader={false}
      disableSticky={isOnlyRead}
      withoutPadding={isOnlyRead}
      name={`Stock transfer ${data.id}`}
      onEdit={isCompletedOrOnlyRead ? undefined : () => navigate('edit')}
      rightSlot={
        isCompletedOrOnlyRead ? undefined : (
          <DeleteDialog
            id={data.id}
            action={remove}
            mutation={mutation}
            name="stock transfer"
            navigateUrl={AppRoutes.Inventory.Transfers.Home}
            successMessage={`Stock transfer ${Messages.DELETED_SUCCESSFULLY}`}
          />
        )
      }
      sections={[
        {
          title: 'General info',
          rows: [
            [
              {
                col: 3,
                colTablet: 3,
                colMobile: 6,
                title: 'Date',
                value: formatDate(data.date),
              },
              {
                col: 3,
                colTablet: 3,
                colMobile: 6,
                title: 'Status',
                value: data.status,
              },
              {
                col: 3,
                colTablet: 3,
                colMobile: 6,
                title: 'Wholesale cost',
                value: formatPrice(data.wholesaleCost),
              },
              {
                col: 3,
                colTablet: 3,
                title: 'Created By',
                value: data.createdBy.name,
              },
            ],
          ],
        },
        {
          title: 'Source storage',
          rows: [
            [
              {
                col: 4,
                colTablet: 4,
                title: 'Name',
                value: data.sourceStorage?.name,
              },
              {
                col: 4,
                colTablet: 4,
                title: 'Address',
                value: data.sourceStorage?.address,
              },
              {
                col: 4,
                colTablet: 4,
                title: 'Phone',
                value: data.sourceStorage?.phone,
              },
              {
                col: 4,
                colTablet: 4,
                title: 'Contact name',
                value: data.sourceStorage?.contactName,
              },
              {
                col: 4,
                colTablet: 4,
                title: 'Emergency Phone',
                value: data.sourceStorage?.emergencyPhone,
              },
            ],
          ],
        },
        ...(data.destinationCompany
          ? [
              {
                title: 'Destination company',
                rows: [
                  [
                    {
                      col: 4,
                      colTablet: 4,
                      title: 'Name',
                      value: data.destinationCompany.name,
                    },
                  ],
                ],
              },
            ]
          : []),
        ...(data.destinationStorage
          ? [
              {
                title: 'Destination storage',
                rows: [
                  [
                    {
                      col: 4,
                      colTablet: 4,
                      title: 'Name',
                      value: data.destinationStorage.name,
                    },
                    {
                      col: 4,
                      colTablet: 4,
                      title: 'Address',
                      value: data.destinationStorage.address,
                    },
                    {
                      col: 4,
                      colTablet: 4,
                      title: 'Phone',
                      value: data.destinationStorage.phone,
                    },
                    {
                      col: 4,
                      colTablet: 4,
                      title: 'Contact name',
                      value: data.sourceStorage?.contactName,
                    },
                    {
                      col: 4,
                      colTablet: 4,
                      title: 'Emergency Phone',
                      value: data.sourceStorage?.emergencyPhone,
                    },
                  ],
                ],
              },
            ]
          : []),
        {
          title: 'Products',
          children: (
            <List
              count={data.items.length}
              style={{ marginBottom: '32px' }}
              mainWrapperStyles={{ padding: 0 }}
              header={<ListHeader padding="8px" titles={titles} />}
            >
              {data.items.map((item) => (
                <ListCard
                  headers={titles}
                  key={item.productId}
                  values={[
                    <Flex gap={8} alignItems="center">
                      <Avatar url={item.product.photoUrl} />
                      <h3>{item.product.name}</h3>
                    </Flex>,
                    item.count,
                    formatPrice(item.price),
                    formatPrice(item.count * item.price),
                  ]}
                />
              ))}

              <ListCard
                withPlaceholders={false}
                values={[
                  <h3>Total: </h3>,
                  '',
                  <h3>{formatPrice(data.wholesaleCost)}</h3>,
                ]}
              />
            </List>
          ),
        },
      ]}
    />
  );
};
