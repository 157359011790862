import { createElement } from 'react';

import { TitleLevel } from 'types';
import { Flex, NewEntityButton } from 'components';

import { CardProps } from './types';

export const Card = ({
  flex,
  title,
  subtitle,
  children,
  maxHeight,
  createPath,
  withoutGap,
  createTitle,
  hasMinHeight,
  cardRightSlot,
  isFooterCreateButton,
  titleLevel = TitleLevel.H2,
}: CardProps) => (
  <Flex
    fullHeight
    flex={flex}
    maxHeight={maxHeight}
    flexDirection="column"
    gap={withoutGap ? 0 : 16}
    minHeight={hasMinHeight ? 0 : undefined}
  >
    <Flex alignItems="center" justifyContent="space-between">
      <div>
        {createElement(titleLevel, { children: title })}
        {subtitle && typeof subtitle === 'string' ? (
          <p>{subtitle}</p>
        ) : (
          subtitle
        )}
      </div>
      {!isFooterCreateButton && createPath && createTitle && (
        <NewEntityButton to={createPath} text={createTitle} />
      )}
      {cardRightSlot}
    </Flex>
    {children}
  </Flex>
);
