import dayjs from 'dayjs';
import { useMemo } from 'react';

import { ButtonColors } from 'types';
import { mapQuery } from 'utils/query';
import { formatPrice } from 'utils/helpers';
import { formatTimezoneSpecific } from 'utils/date';
import { useProfitAndLossesQuery } from 'store/api/event';
import { isMobile, isDesktop, useDownloadFile } from 'hooks';
import { payoutTitles } from 'components/payout-dialog/config';
import {
  Row,
  Col,
  Flex,
  Icon,
  Info,
  Button,
  Details,
  ListCard,
  ListHeader,
} from 'components';

import * as Styles from './styles';
import { CardProps, ProfitAndLossesProps } from './types';
import {
  commonProps,
  revenueTitles,
  expensesTitles,
  getSummaryInfo,
  getExpensesInfo,
  expensesTemplate,
  revenueCellsTablet,
  commonFooterCardProps,
  calculatePaymentMethods,
} from './config';

const Card = ({ title, children }: CardProps) => (
  <Flex fullWidth flexDirection="column">
    <h2>{title}</h2>
    {children}
  </Flex>
);

export const ProfitAndLosses = ({ data, event }: ProfitAndLossesProps) => {
  const mobile = isMobile();
  const desktop = isDesktop();
  const { downloadFile } = useDownloadFile();

  const { data: profitAndLosses, ...props } = useProfitAndLossesQuery(
    String(event.id),
    { skip: !event.id }
  );

  const { totalRevenue, totalPaymentRevenue } = useMemo(
    () => ({
      totalPaymentRevenue: calculatePaymentMethods(data),
      totalRevenue: data?.reduce(
        (acc, value) => {
          acc.tip += value.total.tip;
          acc.netSales += value.total.netSales;
          acc.grossSales += value.total.grossSales;
          return acc;
        },
        { tip: 0, netSales: 0, grossSales: 0 }
      ),
    }),
    [data]
  );

  const { summaryInfo, expensesInfo } = useMemo(
    () => ({
      summaryInfo: getSummaryInfo(profitAndLosses),
      expensesInfo: getExpensesInfo(profitAndLosses),
    }),
    [profitAndLosses]
  );

  return (
    <Details {...mapQuery(props)}>
      <Flex gap={16} flexDirection="column">
        <Card title="Summary">
          <Row>
            {summaryInfo?.map((value) => (
              <Col col={2} colTablet={4} colMobile={6}>
                <Info {...value} />
              </Col>
            ))}
          </Row>
        </Card>
        <Flex gap={16} fullWidth flexDirection={mobile ? 'column' : 'row'}>
          <Card title="Revenue">
            <Row>
              <Col col={4} colTablet={6} colMobile={6}>
                <Info
                  title="Gross Revenue"
                  value={formatPrice(totalRevenue.grossSales)}
                />
              </Col>
              <Col col={4} colTablet={6} colMobile={6}>
                <Info
                  title="Net Revenue"
                  value={formatPrice(totalRevenue.netSales)}
                />
              </Col>
            </Row>
            <Styles.StyledList
              {...commonProps}
              count={data?.length}
              header={<ListHeader titles={revenueTitles} />}
              footer={
                <ListCard
                  {...commonFooterCardProps}
                  headers={[''].concat(revenueTitles.slice(1))}
                  cellsTablet={mobile ? undefined : revenueCellsTablet}
                  values={[
                    'Total Revenue',
                    formatPrice(totalPaymentRevenue.check),
                    formatPrice(totalPaymentRevenue.cash),
                    formatPrice(totalPaymentRevenue.credit),
                    formatPrice(totalRevenue.tip),
                  ]}
                />
              }
            >
              {data?.map((value) => (
                <ListCard
                  key={value.date}
                  headers={revenueTitles}
                  values={[
                    formatTimezoneSpecific(
                      value.checkoutDate,
                      event?.timezone?.code
                    ),
                    formatPrice(value.paymentMethods.check),
                    formatPrice(value.paymentMethods.cash),
                    formatPrice(value.paymentMethods.credit),
                    formatPrice(value.total.tip),
                  ]}
                />
              ))}
            </Styles.StyledList>
          </Card>

          <Card title="Expenses">
            <Row>
              {expensesInfo?.map(({ title, value }) => (
                <Col col={3} colTablet={6} colMobile={6}>
                  <Info title={title} value={formatPrice(value)} />
                </Col>
              ))}
            </Row>
            <Styles.StyledList
              {...commonProps}
              count={profitAndLosses?.payoutItems?.length}
              header={
                <ListHeader
                  titles={expensesTitles}
                  template={expensesTemplate}
                />
              }
              footer={
                <ListCard
                  {...commonFooterCardProps}
                  tabletTemplate="1fr 1fr"
                  template={expensesTemplate}
                  headers={['', 'Total Amount']}
                  values={
                    desktop
                      ? [
                          'Total Expenses',
                          null,
                          formatPrice(profitAndLosses?.totalExpenses),
                          ...Array(3).fill(null),
                        ]
                      : [
                          'Total Expenses',
                          formatPrice(profitAndLosses?.totalExpenses),
                        ]
                  }
                />
              }
            >
              {profitAndLosses?.payoutItems?.map((value) => (
                <ListCard
                  key={value.id}
                  headers={expensesTitles}
                  template={expensesTemplate}
                  values={[
                    value.notes,
                    formatTimezoneSpecific(value.date, event?.timezone?.code),
                    formatPrice(value.amount),
                    payoutTitles[value.type],
                    value.employee?.user?.name,
                    <Button
                      transparent
                      padding="0"
                      tooltip="Download"
                      icon={<Icon.Download />}
                      disabled={!value.receiptUrl}
                      colorType={ButtonColors.LightBlue}
                      onClick={
                        value.receiptUrl
                          ? () =>
                              downloadFile(
                                value.receiptUrl!,
                                `receipt-${value.employee?.user?.name}-${payoutTitles[value.type]}-${dayjs().unix()}`
                              )
                          : undefined
                      }
                    />,
                  ]}
                />
              ))}
            </Styles.StyledList>
          </Card>
        </Flex>
      </Flex>
    </Details>
  );
};
