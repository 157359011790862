import { useContext } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { isMobile, isDesktop } from 'hooks';
import { defaultColors } from 'utils/charts';

import { ThemeContext } from '../../context';

import * as Styles from './styles';
import {
  AxisTickProps,
  BarChartProps,
  BarChartItemProps,
  ResponsiveBarProps,
} from './types';

const CustomTick = (tick: AxisTickProps) => (
  <g transform={`translate(${tick.x},${tick.y + 16})`}>
    <Styles.Text textAnchor="middle" dominantBaseline="middle">
      {tick.value.length > 8 ? `${tick.value.slice(0, 8)}...` : tick.value}
    </Styles.Text>
  </g>
);

export const BarChart = ({
  data,
  theme,
  tooltip,
  axisLeft,
  className,
  axisBottom,
  customTooltip,
  padding = 0.8,
  getTooltipValue,
  enableLabel = false,
  colors = defaultColors,
  ...props
}: BarChartProps): JSX.Element | null => {
  const mobile = isMobile();
  const desktop = isDesktop();
  const { theme: mainTheme } = useContext(ThemeContext);
  const { axisLine, textColor, gridLineStroke } = mainTheme.config.barChart;

  const getTooltip: ResponsiveBarProps['tooltip'] = (bar) =>
    data?.length ? (
      <Styles.Tooltip>
        {getTooltipValue?.(bar.data) ?? bar.data.value}
      </Styles.Tooltip>
    ) : null;

  const getConfigColor: ResponsiveBarProps['colors'] = (bar) =>
    colors[bar.index % colors.length];

  const chartDataLength = mobile ? 2 : 3;

  const chartWidth =
    !desktop && data.length > chartDataLength
      ? `${data.length * 112}px`
      : '100%';

  return (
    <Styles.Container width={chartWidth} className={className}>
      <ResponsiveBar
        data={data}
        padding={padding}
        colors={getConfigColor}
        enableLabel={enableLabel}
        tooltip={tooltip ?? customTooltip ?? getTooltip}
        axisLeft={{
          tickSize: 0,
          tickPadding: 23,
          ...axisLeft,
        }}
        axisBottom={{
          tickSize: 0,
          renderTick: desktop ? undefined : CustomTick,
          ...axisBottom,
        }}
        theme={{
          text: {
            fontSize: 12,
            fill: textColor,
          },
          grid: {
            line: {
              stroke: gridLineStroke,
            },
          },
          axis: {
            domain: {
              line: {
                stroke: axisLine,
              },
            },
          },
          ...theme,
        }}
        {...props}
      />
    </Styles.Container>
  );
};

export type { BarChartItemProps };
