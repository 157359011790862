import { states } from 'config';
import { Storage } from 'store/api/storage';
import { Product } from 'store/api/product/types';
import { PaymentMethod } from 'store/api/payment-methods';
import { FormValues } from 'pages/products/check-out/types';
import {
  User,
  Company,
  Timezone,
  IdObject,
  EventType,
  BaseEntity,
  PayoutType,
  UploadFile,
  Commission,
  BaseResponse,
  SearchRequest,
  PaginationData,
  EventLocationType,
  PaginationRequest,
} from 'types';

import { Payout } from '../payout';
import { Customer } from '../customers';
import { Employee } from '../employees';
import { CartItem } from '../cart/types';
import { Inventory } from '../inventory';
import { Chargeback } from '../accounting';
import { StockOrder } from '../stock-orders';
import { StockTransfer } from '../stock-transfers';

export interface EventLocation extends IdObject {
  name: string;
  width?: number;
  number?: string;
  length?: number;
  income?: number;
}

export type EventStatus = 'new' | 'offered' | 'accepted';

export interface EventTeamMember extends User {
  employee?: Employee;
}

export interface DayResultCount {
  cases: number;
  count: number;
  units: number;
  totalPrice: number;
}

export interface DayResultCountProduct extends IdObject {
  units: 10;
  price: number;
  product: Product;
  createdAt: string;
  updatedAt: string;
  productId: number;
  storageId: number;
  demos?: DayResultCount;
  damaged?: DayResultCount;
  beginning?: DayResultCount;
  addedInventory?: DayResultCount;
}

export interface EventDayResult extends IdObject {
  user: User;
  event: Event;
  notes: string;
  userId: number;
  amount: number;
  eventId: number;
  createdAt: string;
  updatedAt: string;
  countProducts: DayResultCountProduct[];
}

export interface Event extends IdObject {
  name: string;
  tax?: number;
  zip?: number;
  city?: string;
  link?: string;
  phone?: string;
  email?: string;
  notes?: string;
  rating?: number;
  address?: string;
  isClose: boolean;
  endDate?: string;
  vendors?: string;
  storage: Storage;
  photoUrl?: string;
  storageId: number;
  company?: Company;
  leaderId?: number;
  product?: Product;
  lastEditor?: User;
  timezoneId: number;
  timezone: Timezone;
  theme?: BaseEntity;
  startDate?: string;
  updatedAt?: string;
  attendance?: string;
  eventType: EventType;
  sellingDays?: number;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  ratingMessage?: string;
  leader?: EventTeamMember;
  payments: EventPayment[];
  finalPrice: string | null;
  companyId?: Company['id'];
  locations: EventLocation[];
  status: EventStatus | null;
  state?: keyof typeof states;
  floorPlanLink: string | null;
  dayResults?: EventDayResult[];
  notesForClient: string | null;
  teamMembers: EventTeamMember[];
  locationType: EventLocationType;
  floorPlanFileUrl: string | null;
  bookingTeamNotes: string | null;
  questionnaires: EventQuestionnaire[];
  selectedLocations: EventLocation['id'][];
  schedule: {
    date: Date;
    dayOff: boolean;
    time?: { to: number; from: number };
  }[];
}

export interface EventFormValues
  extends Omit<
    Event,
    | 'id'
    | 'theme'
    | 'status'
    | 'endDate'
    | 'product'
    | 'payments'
    | 'startDate'
    | 'locations'
    | 'teamMembers'
    | 'selectedLocations'
  > {
  endDate?: Date;
  startDate?: Date;
  photo?: UploadFile;
  themeId: number | null;
  floorPlan?: UploadFile;
  productId: number | null;
  teamMembers: User['id'][];
  locations: Partial<EventLocation>[];
  payments: {
    date?: Date;
  }[] &
    Omit<Partial<EventPayment>, 'date'>;
}

export const eventPaymentTypeOptions = ['payment', 'paymentPlan'] as const;
export type EventPaymentType = (typeof eventPaymentTypeOptions)[number];
export const eventPaymentTypeLabel: Record<EventPaymentType, string> = {
  payment: 'Payment ',
  paymentPlan: 'Payment Plan',
};

export const eventPaymentTransactionOptions = [
  'deposit',
  'partialPayment',
  'fullPayment',
  'electricity',
  'coi',
  'misc',
];
export type EventPaymentTransaction =
  (typeof eventPaymentTransactionOptions)[number];
export const eventPaymentTransactionLabel: Record<
  EventPaymentTransaction,
  string
> = {
  coi: 'COI',
  misc: 'Misc',
  deposit: 'Deposit',
  electricity: 'Electricity',
  fullPayment: 'Full Payment',
  partialPayment: 'Partial Payment',
};

export interface EventPayment extends IdObject {
  date?: string;
  paidBy?: User;
  notes?: string;
  amount?: number;
  cashed?: boolean;
  type: EventPaymentType;
  paymentMethod?: PaymentMethod;
  transaction?: EventPaymentTransaction;
}

export interface DailyCountProductValue {
  cases?: number;
  units?: number;
}

export interface DailyCountProduct
  extends Omit<
    Inventory,
    'demos' | 'damaged' | 'beginning' | 'addedInventory'
  > {
  demos?: DailyCountProductValue;
  damaged?: DailyCountProductValue;
  beginning?: DailyCountProductValue;
  addedInventory?: DailyCountProductValue;
}

export interface EventOpenCloseDayRequest extends IdObject {
  notes?: string;
  amount?: number;
  countProducts?: DailyCountProduct[];
}

export interface EventCheckoutRequest extends IdObject, FormValues {
  cartId: number;
  subTotal: number;
  shippingPrice?: number;
}

export type LostCommission = Commission;

export interface EventCheckout extends IdObject, Omit<FormValues, 'customer'> {
  user: User;
  tax?: number;
  event: Event;
  userId: number;
  pdfUrl?: string;
  eventId: number;
  comment?: string;
  subTotal: number;
  taxAmount: number;
  items: CartItem[];
  createdAt: string;
  updatedAt: string;
  saleTotal: number;
  grossTotal: number;
  customerId?: number;
  customer?: Customer;
  processingFees: number;
  shippingPrice?: string;
  chargeback?: Chargeback;
  processingFeesAmount?: number;
  lostCommissions?: LostCommission[];
}

export interface EventReportUser {
  name: string;
  userId: number;
  bonuses: number;
  advances: number;
  reimbursements: number;
  createdCheckout: number;
}

export interface EventReportProduct extends Inventory {
  name: string;
  price: number;
  productId: number;
  totalSold: number;
  remaining: number;
  totalRevenue: number;
  productRevenue: number;
}

export interface EventReportCommission {
  name: string;
  tips: number;
  email: string;
  totalCommission: number;
}

export interface EventReportTotal {
  tax: number;
  tip: number;
  total: number;
  netSales: number;
  grossSales: number;
  totalAmount: number;
  processingFees: number;
  shippingPrices: number;
}

export interface EventReportEmployee {
  name: string;
  tip?: number;
  email: string;
  total: number;
  grossSales: number;
}

export interface EventReport {
  date: string;
  expenses: Payout[];
  checkoutDate: string;
  total: EventReportTotal;
  users: EventReportUser[];
  products: EventReportProduct[];
  employees: EventReportEmployee[];
  commissions: EventReportCommission[];
  paymentMethods: Record<string, number>;
}

export interface EventReportRequest {
  id: string;
  isDailyReport?: boolean;
}

export interface EventEmployeesResponse extends User {
  bonuses: Payout[];
  advances: Payout[];
  employee: Employee;
}

export enum AdditionalCashManagerType {
  Sale = 'Sale',
  Beginning = 'Beginning',
  DayResult = 'Day Result',
}

export const CashManagerType = {
  ...PayoutType,
  ...AdditionalCashManagerType,
};

export interface EventCashManagerResponse {
  date: string;
  amount: number;
  employeeName: string;
  type: (typeof CashManagerType)[keyof typeof CashManagerType];
}

export interface ReceiptsRequest extends IdObject {
  usersId?: string;
}

export interface ReceiptDetailsRequest extends IdObject {
  receiptId: string;
}

export interface EventQuestionnaire extends IdObject {
  user: User;
  event: Event;
  userId: number;
  eventId: number;
  visitors: string;
  weakDays: string;
  boothSize: string;
  comments?: string;
  updatedAt: string;
  createdAt: string;
  repsNextYear: string;
  generalScore: number;
  locationScore: number;
  teamWorkIssue: string;
  logisticsIssue: string;
  houseOwnerInfo: string;
  locationRequest: string;
  additionalBooth: string;
  accommodationScore: number;
  manageEventNextYear: string;
}

export type EventQuestionnaireRequest = Omit<
  EventQuestionnaire,
  'user' | 'event' | 'createdAt' | 'updatedAt'
>;

export interface EmployeeCheckout {
  monthSales: number;
  yearlySales: number;
  checkouts: EventCheckout[];
}

export interface EmployeeCheckoutRequest extends IdObject {
  eventId?: string;
}

export interface EventTopSeller {
  user: User;
  userId: number;
  netSales: number;
  commission: number;
  totalSales: number;
}

export interface EventTopSellerResponse {
  checkoutsTotal: number;
  topSellers: EventTopSeller[];
}

export interface EventTopSellerRequest {
  ids?: string;
  date?: string;
}

export interface ReceiptHTMLRequest {
  id: string;
  receiptId: string;
}

export interface ReportPdfResponse {
  jobId: string;
}

export interface EventDashboardDailySale {
  date: string;
  sales: number;
}

export interface EventDashboardTopSellingProduct {
  sales: number;
  product: Product;
  quantity: number;
}

export interface EventDashboardDamagedProducts {
  totalCost: number;
  totalCount: number;
}

export interface EventDashboardStatistics {
  refund: number;
  netSales: number;
  salesTax: number;
  shipping: number;
  totalTips: number;
  grossSales: number;
  totalSales: number;
  processingFee: number;
  totalProducts: number;
  checkoutCounts: number;
  productRevenuePercentage: number;
  dailySales: EventDashboardDailySale[];
  damagedProducts: EventDashboardDamagedProducts;
  topSellingProducts: EventDashboardTopSellingProduct[];
}

export interface EventDashboardStatisticsRequest {
  id: string;
  date: string;
}

export type EventListResponse = PaginationData<'rows', Event>;

export type EventListResponseData = BaseResponse<EventListResponse>;

export type EventListRequest = SearchRequest &
  Partial<Event> &
  PaginationRequest;

export interface EventInventoryStatisticStart {
  name: string;
  total: number;
  price: string;
  masters: number;
  singles: number;
}

export interface EventInventoryStatisticEnd
  extends Pick<
    EventInventoryStatisticStart,
    'name' | 'price' | 'masters' | 'singles'
  > {
  demos: number;
  total: number;
  damaged: number;
  unitPerCase: string;
}

export interface EventInventoryStatisticSold
  extends Pick<EventInventoryStatisticStart, 'name' | 'price'> {
  quantity: number;
  discount: number;
  productId: number;
  totalPrice: number;
  productRevenue: number;
}

export interface EventInventoryStatistic {
  end: EventInventoryStatisticEnd[];
  sold: EventInventoryStatisticSold[];
  start: EventInventoryStatisticStart[];
}

export interface EventEmployeeCommissionsRequest extends IdObject {
  userId: number;
}

export interface EventEmployeeCommissionInfo {
  tip: number;
  pay: number;
  date: string;
  netSale: number;
  bonuses: number;
  advances: number;
  grossSale: number;
  commissions: number;
}

export interface EventEmployeeCommission {
  pay: number;
  totalNet: number;
  totalTips: number;
  totalBonus: number;
  totalGross: number;
  totalAdvance: number;
  totalCommissions: number;
  commissions: EventEmployeeCommissionInfo[];
}

export interface EventProfitAndLoss {
  profit: number;
  totalTax: number;
  workingDays: number;
  productCost: number;
  totalExpenses: number;
  totalNetSales: number;
  payoutItems: Payout[];
  profitPercentage: number;
  totalCommissions: number;
  totalProcessingFees: number;
  totalProductSoldCost: number;
  productRevenuePercentage: number;
  totalExpensesWithCommissions: number;
}

export interface EventStockOrdersInfoResponse {
  stockOrders: StockOrder[];
  stockTransfers: StockTransfer[];
}
