import styled, { css } from 'styled-components';

import { GeneralInfo } from 'components/general-info';

export const StyledGeneralInfo = styled(GeneralInfo)<{
  withoutPadding?: boolean;
}>`
  ${({ withoutPadding }) =>
    withoutPadding &&
    css`
      width: 100%;
      padding: unset;
    `}
`;
