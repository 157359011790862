import styled from 'styled-components';

export const TabContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const RevenueContainer = styled.div`
  width: ${({ theme }) => (theme.responsive.isMobile ? '75vw' : '332px')};
`;
