import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { addParamsToUrl } from 'utils/query';
import { calculateOffset } from 'utils/helpers';

import { apiQuery } from '../../query';
import {
  commissionLevelApi,
  tagTypes as commissionLevelTagTypes,
} from '../commission-level';

import {
  Employee,
  EmployeeFormValues,
  EmployeeListRequest,
  EmployeeListResponse,
  CreateEmployeeFormValues,
} from './types';

export const tagTypes = ['Employees'];

export const employeesApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'employeesApi',
  endpoints: (build) => ({
    getInfoAboutMe: build.query<Employee, void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.EMPLOYEE_ME,
      }),
    }),
    get: build.query<Employee, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.EMPLOYEE.replace(':id', id),
      }),
    }),
    remove: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: URL.EMPLOYEE.replace(':id', id),
      }),
    }),
    create: build.mutation<void, CreateEmployeeFormValues>({
      invalidatesTags: tagTypes,
      query: (data) => {
        delete data.repeatPassword;

        return {
          data,
          method: 'post',
          url: URL.EMPLOYEES,
        };
      },
    }),
    list: build.query<EmployeeListResponse, EmployeeListRequest | void>({
      providesTags: tagTypes,
      query: (params) => {
        const { page = 1, limit = 20, ...otherParams } = params || {};
        const offset = calculateOffset(page, limit);
        const searchParams = {
          limit,
          offset,
          ...otherParams,
        };

        return {
          method: 'get',
          url: addParamsToUrl(URL.EMPLOYEES, searchParams),
        };
      },
    }),
    edit: build.mutation<void, EmployeeFormValues>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.EMPLOYEE.replace(':id', String(id)),
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            commissionLevelApi.util.invalidateTags(commissionLevelTagTypes)
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useEditMutation,
  useCreateMutation,
  useRemoveMutation,
  useGetInfoAboutMeQuery,
} = employeesApi;

export type { Employee, EmployeeFormValues, CreateEmployeeFormValues };
