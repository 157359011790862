import dayjs from 'dayjs';
import { generatePath } from 'react-router-dom';

import { AppRoutes } from 'config';
import { Flex } from 'components/flex';
import { formatDate } from 'utils/date';
import { EventCheckout } from 'store/api/event';
import { useNavigate, useShowMore } from 'hooks';
import { formatPrice, joinStrings } from 'utils/helpers';
import { TemplateList } from 'components/transactions-list';

import { SalesListProps } from './types';

const { EditTransaction } = AppRoutes.Dashboard.Analytics;

export const salesConfig = {
  template: 'repeat(5, 1fr)',
  headers: ['Location', 'Date & Time', 'Invoice', 'Net Sale', 'Employee'],
  cellsMobile: [
    { row: '1/2', col: '1/3' },
    { row: '2/3', col: '1/2' },
    { row: '2/3', col: '2/3' },
  ],
};

export const SalesList = ({ data, withoutNavigate }: SalesListProps) => {
  const navigate = useNavigate();

  const { visibleData, ...showMoreProps } = useShowMore(data);

  return (
    <TemplateList<EventCheckout>
      {...salesConfig}
      title="Sales"
      data={visibleData}
      hasMinHeight={false}
      listProps={showMoreProps}
      tabletTemplate={salesConfig.template}
      maxHeight={visibleData?.length ? 350 : undefined}
      onClick={
        !withoutNavigate
          ? (e, value) =>
              navigate(generatePath(EditTransaction, { id: value.id }))
          : undefined
      }
      render={(sale) => {
        const timezoneDate = dayjs(sale.createdAt).tz(
          sale.event?.timezone?.code
        );
        return [
          sale.event?.name,
          <Flex flexDirection="column">
            <p>{formatDate(timezoneDate)}</p>
            <p>{formatDate(timezoneDate, 'LT')}</p>
          </Flex>,
          sale.id,
          formatPrice(sale.subTotal),
          joinStrings(
            sale?.commissions?.map((commission) => commission.user?.name) || [],
            ', '
          ),
        ];
      }}
    />
  );
};
