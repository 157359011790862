import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { CheckoutPdfUrlEvent } from 'context';

import { eventApi, ReceiptDetailsRequest } from '../api/event';
import { accountingApi, TransactionRequest } from '../api/accounting';
import { eventOrdersApi, EventOrderListRequest } from '../api/event-orders';

/**
 * Updates the `pdfUrl` field in cached query data for checkouts
 * when a `CheckoutPdfUrlEvent` is received.
 *
 * @param event - The event containing the updated `id` and `pdfUrl` to update in cache.
 */
export const updateCheckoutPdfUrlInCache = createAsyncThunk<
  void,
  CheckoutPdfUrlEvent,
  { state: RootState }
>('pdf/update-cache', async (event, { dispatch, getState }) => {
  const { id, url } = event;

  const state = getState();
  const accountingQueries = state.accountingApi.queries;
  const eventsQueries = state.eventApi.queries;
  const eventOrdersQueries = state.eventOrdersApi.queries;

  const transactionsParams = Object.keys(accountingQueries)
    .map((key) => {
      const queryDetails = accountingQueries[key];
      if (
        !queryDetails ||
        !queryDetails.endpointName ||
        queryDetails.endpointName !== 'transactions'
      ) {
        return;
      }
      return queryDetails.originalArgs;
    })
    .filter(Boolean);

  const receiptDetailsParams = Object.keys(eventsQueries)
    .map((key) => {
      const queryDetails = eventsQueries[key];
      if (
        !queryDetails ||
        !queryDetails.endpointName ||
        !queryDetails.originalArgs ||
        queryDetails.endpointName !== 'receiptDetails'
      ) {
        return;
      }

      const args = queryDetails.originalArgs as ReceiptDetailsRequest;
      if (args.receiptId !== String(id)) {
        return;
      }

      return args;
    })
    .filter(Boolean);

  const eventOrdersParams = Object.keys(eventOrdersQueries)
    .map((key) => {
      const queryDetails = eventOrdersQueries[key];
      if (
        !queryDetails ||
        !queryDetails.endpointName ||
        queryDetails.endpointName !== 'list'
      ) {
        return;
      }
      return queryDetails.originalArgs;
    })
    .filter(Boolean);

  transactionsParams.forEach((params) => {
    dispatch(
      accountingApi.util.updateQueryData(
        'transactions',
        params as TransactionRequest,
        (draft) => {
          draft?.rows?.forEach((item) => {
            if (item.id === id) {
              item.pdfUrl = url;
            }
          });
        }
      )
    );
  });

  receiptDetailsParams.forEach((params) => {
    dispatch(
      eventApi.util.updateQueryData('receiptDetails', params!, (draft) => {
        if (draft) {
          draft.pdfUrl = url;
        }
      })
    );
  });

  eventOrdersParams.forEach((params) => {
    dispatch(
      eventOrdersApi.util.updateQueryData(
        'list',
        params as EventOrderListRequest,
        (draft) => {
          draft.forEach((item) => {
            if (item.checkout?.id === id) {
              item.checkout.pdfUrl = url;
            }
          });
        }
      )
    );
  });
});
