import { isMobile } from 'hooks';
import { ButtonColors } from 'types';
import { formatPrice } from 'utils/helpers';
import { Icon, Dialog, GeneralInfo, ButtonWithRef } from 'components';

import * as Styles from './styles';
import { CommissionDetailsDialogProps } from './types';

const commonColProps = {
  col: 4,
  colTablet: 4,
};

export const CommissionDetailsDialog = ({
  data,
  selectedEmployee,
}: CommissionDetailsDialogProps) => {
  const mobile = isMobile();
  return (
    <Dialog
      showCloseButton
      width={mobile ? '90wv' : '504px'}
      trigger={
        <ButtonWithRef
          transparent
          padding="0"
          icon={<Icon.Info />}
          text="Show Total Info"
          colorType={ButtonColors.LightBlue}
        />
      }
    >
      {() => (
        <GeneralInfo
          withoutPhoto
          withPlaceholders
          stickyWithHeader={false}
          name={`${selectedEmployee?.name} Total Info`}
          sections={[
            {
              rows: [
                [
                  {
                    ...commonColProps,
                    title: 'Total Gross',
                    value: formatPrice(data?.totalGross),
                  },
                  {
                    ...commonColProps,
                    title: 'Total Net',
                    value: formatPrice(data?.totalNet),
                  },
                  {
                    ...commonColProps,
                    title: 'Commissions',
                    value: formatPrice(data?.totalCommissions),
                  },
                  {
                    ...commonColProps,
                    title: 'Tips',
                    value: formatPrice(data?.totalTips),
                  },
                  {
                    ...commonColProps,
                    title: 'Reimbursemets',
                    value: (
                      <Styles.Reimbursement>
                        {formatPrice(data?.totalAdvance)}
                      </Styles.Reimbursement>
                    ),
                  },
                  {
                    ...commonColProps,
                    title: 'Bonuses',
                    value: formatPrice(data?.totalBonus),
                  },
                  {
                    ...commonColProps,
                    title: 'Pay',
                    value: formatPrice(data?.pay),
                  },
                ],
              ],
            },
          ]}
        />
      )}
    </Dialog>
  );
};
