import { isMobile } from 'hooks';
import { formatPrice } from 'utils/helpers';
import { useReportQuery } from 'store/api/event';
import { formatTimezoneSpecific } from 'utils/date';
import {
  Info,
  Flex,
  Drawer,
  Loader,
  ListCard,
  ListHeader,
  Placeholder,
} from 'components';

import * as CommonStyles from '../styles';
import { DailyReportProps } from '../types';
import { EventHeader } from '../event-header';
import { ProductList } from '../product-list';

import * as Styles from './styles';
import { usersConfig, employeeSalesConfig } from './config';

export const DailyReport = ({ event, opened, onClose }: DailyReportProps) => {
  const mobile = isMobile();

  const { data, isLoading } = useReportQuery({
    isDailyReport: true,
    id: String(event.id),
  });

  const report = data?.[0];

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      topOffset={mobile ? '10%' : '20%'}
    >
      <Loader isFullScreen={false} isLoading={isLoading}>
        {report ? (
          <Flex fullHeight gap={mobile ? 16 : 32} flexDirection="column">
            <EventHeader
              event={event}
              isDailyReport
              onClose={onClose}
              title="Daily Sale Report & Inventory"
              date={formatTimezoneSpecific(
                report.checkoutDate,
                event?.timezone?.code
              )}
            />

            <Styles.Container>
              <Styles.SalesContainer>
                <CommonStyles.StyledList
                  count={report.employees.length}
                  mainWrapperStyles={{ padding: 0 }}
                  header={
                    <ListHeader
                      padding="8px"
                      titles={employeeSalesConfig.titles}
                      template={employeeSalesConfig.template}
                    />
                  }
                  footer={
                    <ListCard
                      selected
                      padding="16px"
                      template={employeeSalesConfig.template}
                      values={[
                        'Total',
                        report?.total?.grossSales &&
                          formatPrice(report?.total?.grossSales),
                        report?.total?.tip && formatPrice(report?.total?.tip),
                        report?.total?.total &&
                          formatPrice(report?.total?.total),
                      ]}
                    />
                  }
                >
                  {report.employees.map((value) => (
                    <ListCard
                      {...employeeSalesConfig}
                      padding="16px"
                      key={value.name}
                      headers={employeeSalesConfig.titles}
                      tabletTemplate={employeeSalesConfig.template}
                      values={[
                        value.name,
                        formatPrice(value.grossSales),
                        formatPrice(value?.tip),
                        formatPrice(value?.total),
                      ]}
                    />
                  ))}
                </CommonStyles.StyledList>

                <ListCard
                  selected
                  padding="16px"
                  values={[
                    <h4>Total Gross</h4>,
                    <Flex flexDirection="column">
                      <p>Cash</p>
                      <p className="semibold">
                        {formatPrice(report.paymentMethods.cash)}
                      </p>
                    </Flex>,
                    <Flex flexDirection="column">
                      <p>Credit</p>
                      <p className="semibold">
                        {formatPrice(report.paymentMethods.credit)}
                      </p>
                    </Flex>,
                    <Flex flexDirection="column">
                      <p>Check</p>
                      <p className="semibold">
                        {formatPrice(report.paymentMethods.check)}
                      </p>
                    </Flex>,
                  ]}
                />

                {event.notes && <Info title="Notes" value={event.notes} />}
              </Styles.SalesContainer>

              <Flex gap={16} fullWidth flexDirection="column">
                <ProductList products={report.products} />

                <CommonStyles.StyledList
                  count={report.users.length}
                  mainWrapperStyles={{ padding: 0 }}
                  header={
                    <ListHeader
                      padding="8px"
                      titles={usersConfig.titles}
                      template={usersConfig.template}
                    />
                  }
                >
                  {report.users.map((value) => (
                    <ListCard
                      padding="16px"
                      key={value.userId}
                      headers={usersConfig.titles}
                      template={usersConfig.template}
                      tabletTemplate={usersConfig.template}
                      values={[
                        value.name,
                        value.createdCheckout,
                        formatPrice(value.advances),
                        formatPrice(value.reimbursements),
                        formatPrice(value.bonuses),
                      ]}
                    />
                  ))}
                </CommonStyles.StyledList>
              </Flex>
            </Styles.Container>
          </Flex>
        ) : !isLoading ? (
          <Placeholder text="There were no sales today" />
        ) : null}
      </Loader>
    </Drawer>
  );
};
