import { useMemo, useState } from 'react';

export const useShowMore = <T>(
  data?: T[],
  initialSize = 20,
  increment = 20
) => {
  const [visibleCount, setVisibleCount] = useState(initialSize);

  const visibleData = useMemo(
    () => data?.slice(0, visibleCount),
    [data, visibleCount]
  );

  const onShowMore = () => {
    setVisibleCount((prev) => Math.min(prev + increment, data?.length ?? 0));
  };

  const hasMore = visibleCount < (data?.length ?? 0);

  return { hasMore, onShowMore, visibleData };
};
