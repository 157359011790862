import { io } from 'socket.io-client';
import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';

import { Config } from 'config';
import { decryptToken } from 'utils/storage';
import { useLocalStorageListener } from 'hooks';

import {
  Socket,
  SocketEvents,
  SocketContextProps,
  CheckoutPdfUrlEvent,
} from './types';

const getInitialState = (): SocketContextProps => ({
  isConnected: () => false,
  subscribe: () => () => {},
});

export const SocketContext =
  createContext<SocketContextProps>(getInitialState());

export const useSocket = () => useContext(SocketContext);

export const SocketContextProvider = ({ children }: PropsWithChildren) => {
  const [socket, setSocket] = useState<Socket | undefined>(undefined);

  const { currentValue: token } = useLocalStorageListener('accessToken');

  useEffect(() => {
    if (!token) {
      return;
    }

    const newSocket = io(Config.socketURL, {
      reconnectionDelayMax: 5000,
      auth: {
        token: decryptToken(token),
      },
    });
    setSocket(newSocket);

    newSocket.onAny((eventName) => {
      console.log('socket event', eventName);
    });

    newSocket.io.on('error', (error) => {
      console.error('socket error', error);
    });

    newSocket.on('connect', () => {
      console.log('socket connected');
    });

    newSocket.on('disconnect', () => {
      console.log('socket disconnected');
    });

    newSocket.io.on('reconnect', (attempt) => {
      console.log('socket reconnected on attempt', attempt);
    });

    newSocket.io.on('reconnect_attempt', (attempt) => {
      console.log('socket reconnect attempt', attempt);
    });

    newSocket.io.on('reconnect_error', (error) => {
      console.error('socket reconnect error', error);
    });

    newSocket.io.on('reconnect_failed', () => {
      console.error('socket reconnect failed');
    });

    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(undefined);
      }
    };
  }, [token]);

  /**
   * Subscribes to a specific socket event with a callback, and cleans up the listener
   * when the subscription is no longer needed by calling the returned cleanup function.
   *
   * @param event - The name of the socket event to subscribe to.
   * @param callback - The callback function to be executed when the event is triggered.
   * @returns A cleanup function that removes the listener for the specified event.
   */
  const subscribe = useCallback(
    <K extends keyof SocketEvents>(event: K, callback: SocketEvents[K]) => {
      const eventString = String(event);
      socket?.on(eventString, callback);

      return () => {
        socket?.off(eventString, callback);
      };
    },
    [socket]
  );

  return (
    <SocketContext.Provider
      value={{
        socket,
        subscribe,
        isConnected: () => socket?.connected || false,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export type { CheckoutPdfUrlEvent };
