import styled, { css } from 'styled-components';

import { flexSpaceBetween } from 'styles';

export const Reimbursement = styled.p`
  color: ${({ theme }) => theme.colors.red.primary.standard};
`;

export const HeaderButtonsContainer = styled.div`
  ${flexSpaceBetween};
  ${({ theme }) =>
    !theme.responsive.isDesktop &&
    css`
      padding-bottom: 16px;
    `}
`;
