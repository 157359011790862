import {
  CardType,
  EventType,
  DepositType,
  PayoutStatus,
  CustomerStatus,
  TrasnactionType,
  ChargebackReason,
  EventOrderStatus,
  ChargebackStatus,
  MonthlyTaxStatus,
  EventLocationType,
  PriceApprovalStatus,
  EmployeePaymentMethodType,
} from './enums';

export const EventTypeTitle = {
  [EventType.Indoor]: 'Indoor',
  [EventType.Outdoor]: 'Outdoor',
};

export const EventLocationTypeTitle = {
  [EventLocationType.Sqf]: 'SQF',
  [EventLocationType.Corner]: 'Corner',
  [EventLocationType.Endcap]: 'Endcap',
  [EventLocationType.Inline]: 'Inline',
  [EventLocationType.Island]: 'Island',
};

export const EventOrderStatusTitle = {
  [EventOrderStatus.PENDING]: 'Pending',
  [EventOrderStatus.DELIVERED]: 'Delivered',
  [EventOrderStatus.EXCEPTION]: 'Exception',
  [EventOrderStatus.IN_TRANSIT]: 'In transit',
  [EventOrderStatus.NOT_SHIPPED]: 'Not shipped',
};

export const PriceApprovalStatusTitle = {
  [PriceApprovalStatus.Pending]: 'Pending',
  [PriceApprovalStatus.Approved]: 'Approved',
  [PriceApprovalStatus.Rejected]: 'Rejected',
};

export const PayoutStatusTitle = {
  [PayoutStatus.Paid]: 'Paid',
  [PayoutStatus.Pending]: 'Pending',
  [PayoutStatus.Approved]: 'Approved',
  [PayoutStatus.InProgress]: 'In progress',
  [PayoutStatus.NotApproved]: 'Not approved',
};

export const DepositTypeTitle = {
  [DepositType.Deposit]: 'Deposit',
  [DepositType.HeldFunds]: 'Held funds',
  [DepositType.Withdrawals]: 'Withdrawals',
  [DepositType.ResolvedTransactions]: 'Resolved transactions',
};

export const ChargebackStatusTitle = {
  [ChargebackStatus.Review]: 'Under review',
  [ChargebackStatus.ClosedWon]: 'Closed - WON',
  [ChargebackStatus.ClosedLost]: 'Closed - LOST',
  [ChargebackStatus.ActionRequired]: 'Action required',
};

export const ChargebackReasonTitle = {
  [ChargebackReason.Duplicate]: 'Duplicate Processing',
  [ChargebackReason.IncorrectAmount]: 'Incorrect Amount',
  [ChargebackReason.Defective]: 'Defective/Not as Described',
  [ChargebackReason.GoodsCancelled]: 'Goods/Services Cancelled',
  [ChargebackReason.CancelledService]: 'Cancelled Merchandise/Services',
  [ChargebackReason.AbsentEnvironment]: 'Fraud-Card Absent Environment',
  [ChargebackReason.GoodsReturned]: 'Goods/Services Returned or Refused',
};

export const MonthlyTaxStatusTitle = {
  [MonthlyTaxStatus.Due]: 'Due',
  [MonthlyTaxStatus.Paid]: 'Paid',
  [MonthlyTaxStatus.PartialPaid]: 'Partial paid',
};

export const CardTitle = {
  [CardType.Visa]: 'Visa',
  [CardType.Discover]: 'Discover',
  [CardType.UnionPay]: 'Union Pay',
  [CardType.Mastercard]: 'Mastercard',
  [CardType.AmericanExpress]: 'American Express',
};

export const CustomerStatusTitle = {
  [CustomerStatus.New]: 'New',
  [CustomerStatus.Existing]: 'Existing',
};

export const TransactionTypeTitle = {
  [TrasnactionType.Chargeback]: 'Chargeback',
};

export const EmployeePaymentMethodTypeTitle = {
  [EmployeePaymentMethodType.ACH]: 'ACH',
  [EmployeePaymentMethodType.Wire]: 'Wire',
};
