import { generatePath, useOutletContext } from 'react-router-dom';

import { formatPrice } from 'utils/helpers';
import { Messages, AppRoutes } from 'config';
import { DeleteDialog, TransactionsList } from 'components';
import { Payout, useDeleteMutation } from 'store/api/payout';
import {
  useFilter,
  useShowMore,
  useNavigate,
  handleMutation,
  useSearchQuery,
} from 'hooks';

import { OutletContext } from '../../types';
import { getCommonListProps } from '../config';

const { New, Details } = AppRoutes.Profile.Bonuses;

export const Bonuses = () => {
  const navigate = useNavigate();
  const { payout, isCompanyOwner } = useOutletContext<OutletContext>();

  const { debounceQuery, ...search } = useSearchQuery();
  const filteredData = useFilter<Payout>({
    query: debounceQuery,
    data: payout?.bonuses || [],
    paths: ['event.name', 'amount', 'notes'],
  });

  const [remove, mutation] = useDeleteMutation();
  handleMutation({
    ...mutation,
    successMessage: `Advance ${Messages.DELETED_SUCCESSFULLY}`,
  });

  const { visibleData, ...showMoreProps } = useShowMore(filteredData);

  return (
    <TransactionsList
      {...getCommonListProps(filteredData)}
      title="Bonuses"
      createPath={New}
      data={visibleData}
      isFooterCreateButton
      listProps={{ ...search, ...showMoreProps }}
      createTitle={isCompanyOwner ? 'New bonuses' : undefined}
      template={isCompanyOwner ? '1fr 3fr 1fr 1fr 1fr 24px' : undefined}
      onClick={(e, value) =>
        navigate(generatePath(Details, { bonusId: value.id }))
      }
      extendRender={
        isCompanyOwner
          ? (value) => [
              <DeleteDialog
                id={value.id}
                action={remove}
                title="Delete advance"
                name={`advance with ${formatPrice(value.amount)}`}
              />,
            ]
          : undefined
      }
    />
  );
};
