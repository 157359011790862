import { useUpdateEffect } from 'react-use';
import { useLocation } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import scrollIntoView from 'scroll-into-view-if-needed';
import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';

import { Nullable } from 'types';

const parsePage = (page?: Nullable<string>) => {
  if (!page) {
    return undefined;
  }
  return parseInt(page);
};

const replaceUrl = (params: URLSearchParams) =>
  window.history.replaceState(
    null,
    '',
    params.size > 0
      ? `${location.pathname}?${params.toString()}`.replace(/%2C/g, ',')
      : location.pathname
  );

export const useSearchPagination = (
  currentState?: string,
  debounceDelay = 500
) => {
  let initialPage = 1;
  const location = useLocation();
  const [state, setState] = useState(currentState);

  useMemo(() => {
    if (!currentState || currentState === state) {
      initialPage =
        parsePage(new URLSearchParams(location.search).get('page')) || 1;
    }
  }, [state, location, currentState]);

  const [currentPage, setCurrentPage] = useState(initialPage);

  const [searchQuery, setSearchQuery] = useState(
    new URLSearchParams(location?.search).get('query') ?? ''
  );

  const [debounceQuery] = useDebounceValue(searchQuery, debounceDelay);

  useUpdateEffect(() => {
    const params = new URLSearchParams(location.search);
    setCurrentPage(parsePage(params.get('page')) || 1);
    setSearchQuery(params.get('query') ?? '');
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (currentState && currentState !== state) {
      setCurrentPage(1);
      setState(currentState);

      const params = new URLSearchParams(location?.search || '');
      params.delete('page');
      replaceUrl(params);
    }
  }, [state, location, currentState]);

  useUpdateEffect(() => {
    setCurrentPage(1);
  }, [debounceQuery]);

  const scrollToTop = useCallback(() => {
    const scrollEl = document.getElementById('table-top');
    if (scrollEl) {
      scrollIntoView(scrollEl, {
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
        scrollMode: 'if-needed',
      });
    }
  }, []);

  const changePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      const params = new URLSearchParams(location?.search);
      if (page > 1) {
        params.set('page', String(page));
      } else {
        params.delete('page');
      }
      replaceUrl(params);
      scrollToTop();
    },
    [scrollToTop, location.pathname, location.search]
  );

  useEffect(() => {
    const hasQuery = searchQuery?.length > 0;
    const params = new URLSearchParams(location?.search || '');
    if (hasQuery) {
      params.set('query', searchQuery);
    } else {
      params.delete('query');
    }
    replaceUrl(params);
  }, [searchQuery]);

  return {
    currentPage,
    scrollToTop,
    searchQuery,
    setSearchQuery,
    setCurrentPage: changePage,
    debounceQuery: debounceQuery.trim(),
  };
};
