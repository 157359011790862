import { useShowMore } from 'hooks';
import { EmptyPlaceholder } from 'config';
import { formatPrice } from 'utils/helpers';
import { AnalyticsProduct } from 'store/api/analytics';
import { TemplateList } from 'components/transactions-list';

import { ProductSoldListProps } from './types';

export const productConfig = {
  template: '2fr repeat(4, 1fr)',
  headers: ['Product', 'QTY sold', 'Price', 'Total sold', 'Losses'],
  cellsMobile: [
    { row: '1/2', col: '1/3' },
    { row: '2/3', col: '1/2' },
    { row: '2/3', col: '2/3' },
  ],
};

export const ProductSoldList = ({ data }: ProductSoldListProps) => {
  const { visibleData, ...showMoreProps } = useShowMore(data);

  return (
    <TemplateList<AnalyticsProduct>
      {...productConfig}
      data={visibleData}
      hasMinHeight={false}
      title="Products sold"
      listProps={showMoreProps}
      tabletTemplate={productConfig.template}
      maxHeight={visibleData?.length ? 350 : undefined}
      render={(product) => [
        product?.product?.name,
        product?.quantity,
        formatPrice(product?.product?.costPrice),
        formatPrice(product?.totalSales),
        EmptyPlaceholder,
      ]}
    />
  );
};
