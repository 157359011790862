import { useNavigate } from 'hooks';
import { List } from 'components/list';
import { Link } from 'components/link';
import { formatDate } from 'utils/date';
import { Messages, AppRoutes } from 'config';
import { ListCard } from 'components/list-card';
import { ListHeader } from 'components/list-header';
import { DeleteDialog } from 'components/delete-dialog';
import { formatPhone, formatPrice } from 'utils/helpers';
import { useRemoveMutation } from 'store/api/stock-orders';
import {
  StockOrderStatus,
  StockOrderStatusLabel,
} from 'store/api/stock-orders/types';

import * as Styles from './styles';
import { StockOrderDetailsProps } from './types';

const titles = ['Name', 'Count', 'Price', 'Total price'];
const commonColProps = {
  col: 3,
  colTablet: 3,
  colMobile: 6,
};

export const StockOrderDetails = ({
  data,
  isOnlyRead,
}: StockOrderDetailsProps) => {
  const navigate = useNavigate();
  const [remove, mutation] = useRemoveMutation();

  const isDone = data?.status === StockOrderStatus.Done;

  return (
    <Styles.StyledGeneralInfo
      withoutPhoto
      disableSticky={isOnlyRead}
      withoutPadding={isOnlyRead}
      name={`Stock order ${data.id}`}
      onEdit={isDone || isOnlyRead ? undefined : () => navigate('edit')}
      rightSlot={
        !isOnlyRead ? (
          <DeleteDialog
            id={data.id}
            action={remove}
            name="stock order"
            mutation={mutation}
            navigateUrl={AppRoutes.Shipments.Home}
            successMessage={`Stock order ${Messages.DELETED_SUCCESSFULLY}`}
          />
        ) : undefined
      }
      sections={[
        {
          title: 'General info',
          rows: [
            [
              {
                ...commonColProps,
                title: 'Supplier',
                value: data.supplier.name,
              },
              {
                ...commonColProps,
                title: 'Event',
                value: data.event?.name,
              },
              {
                ...commonColProps,
                title: 'Storage',
                value: data.storage?.name,
              },
              {
                ...commonColProps,
                title: 'Status',
                value: StockOrderStatusLabel[data.status],
              },
            ],
            [
              {
                ...commonColProps,
                title: 'Order Date',
                value: formatDate(data.date),
              },
              {
                ...commonColProps,
                title: 'Number',
                value: data.number,
              },
            ],
            [
              {
                ...commonColProps,
                title: 'Address',
                value: data.address,
              },
              {
                ...commonColProps,
                title: 'Contact Name',
                value: data.contactName,
              },
              {
                ...commonColProps,
                title: 'Phone',
                value: data?.phone ? formatPhone(data.phone) : null,
              },
              {
                ...commonColProps,
                title: 'Emergency Phone',
                value: data?.emergencyPhone
                  ? formatPhone(data.emergencyPhone)
                  : null,
              },
            ],
          ],
        },
        {
          title: 'Shipment Info',
          rows: [
            [
              {
                col: 3,
                colTablet: 3,
                title: 'Carrier',
                value: data.carrier?.name,
              },
              {
                col: 3,
                colTablet: 3,
                title: 'Link to Carrier Website',
                value: data.carrier?.link && (
                  <Link url={data.carrier.link}>{data.carrier.link}</Link>
                ),
              },
            ],
            [
              {
                col: 3,
                colTablet: 3,
                title: 'Tracking #',
                value: data.trackingNumber,
              },
              {
                col: 3,
                colTablet: 3,
                title: 'Estimated Delivery Date',
                value: formatDate(data.estimatedDeliveryDate),
              },
              {
                ...commonColProps,
                title: 'Date Shipped',
                value: formatDate(data.dateShipped),
              },
              {
                ...commonColProps,
                title: 'Shipping cost',
                value: formatPrice(data.ltlPrice),
              },
            ],
          ],
        },
        {
          title: 'Products',
          children: (
            <List
              count={data.items.length}
              style={{ marginBottom: '32px' }}
              mainWrapperStyles={{ padding: 0 }}
              header={<ListHeader padding="8px" titles={titles} />}
            >
              {data.items.map((item) => (
                <ListCard
                  key={item.id}
                  headers={titles}
                  values={[
                    item.product.name,
                    item.count,
                    formatPrice(item.price),
                    formatPrice(item.count * item.price),
                  ]}
                />
              ))}

              <ListCard
                withPlaceholders={false}
                values={[
                  <h3>Total: </h3>,
                  '',
                  <h3>{formatPrice(data.amount)}</h3>,
                ]}
              />
            </List>
          ),
        },
        {
          title: 'Events',
          children: (
            <List
              count={data.events?.length ?? 0}
              header={<ListHeader titles={['Name']} />}
            >
              {data.events?.map((item) => (
                <ListCard key={item.id} values={[item.name]} />
              ))}
            </List>
          ),
        },
      ]}
    />
  );
};
