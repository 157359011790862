import styled, { css } from 'styled-components';

import { Flex } from 'components/flex';
import { Input } from 'components/input';

import { ListProps } from './types';

export const ListWrapper = styled.div<{ $hasMinHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${({ $hasMinHeight }) =>
    $hasMinHeight &&
    css`
      min-height: 0;
    `}
`;

export const SearchContainer = styled(Flex)`
  gap: 24px;
  margin: 16px;
`;

export const SearchInput = styled(Input)`
  flex: 1;
`;

export const Main = styled.div<
  {
    horizontalScrollWidth?: number;
  } & Pick<ListProps, 'gap' | 'horizontalScroll'>
>`
  height: 100%;
  flex: 1 1 auto;
  padding: 0 16px;

  ${({ gap, horizontalScroll, horizontalScrollWidth }) => css`
    ${gap &&
    css`
      > * {
        margin-bottom: ${gap}px;
      }
    `}

    ${horizontalScroll
      ? css`
          width: ${horizontalScrollWidth}px;
          overflow: hidden auto;
        `
      : css`
          overflow: auto;
        `}
  `}
`;

export const PaginationContainer = styled.div<
  Pick<ListProps, 'forceMobileView' | 'horizontalScroll'>
>`
  display: flex;
  align-items: center;
  height: 64px;
  min-height: 64px;
  margin: 0 16px;

  ${({ theme, forceMobileView }) => css`
    box-shadow: inset 0 1px 0 ${theme.config.pagination.container.shadow};

    ${(theme.responsive.isMobile || forceMobileView) &&
    css`
      justify-content: center;
    `}
  `}
`;

export const HorizontalScrollWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: column;
`;

export const ShowMoreContainer = styled.div`
  padding: 16px;
`;
