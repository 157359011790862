import { useState } from 'react';

import { isMobile } from 'hooks';
import { mapQuery } from 'utils/query';
import { formatDate } from 'utils/date';
import { formatPrice } from 'utils/helpers';
import { StockOrder } from 'store/api/stock-orders';
import { useStockOrdersInfoQuery } from 'store/api/event';
import { StockTransfer } from 'store/api/stock-transfers';
import {
  Flex,
  Dialog,
  Details,
  ListCard,
  ListHeader,
  StockOrderDetails,
  StockTransferDetails,
} from 'components';

import * as Styles from './styles';
import { CardProps, StockOrdersProps } from './types';

const transferTitles = ['Date', 'From', 'Wholesale Cost', 'Created By'];
const stockOrdersTitles = ['Order Date', 'Supplier', 'Amount', 'Shipping Cost'];

const isStockTransfer = (
  data: StockOrder | StockTransfer
): data is StockTransfer => 'destinationStorage' in data;

const Card = ({ title, children }: CardProps) => (
  <Flex gap={8} fullWidth flexDirection="column">
    <h3>{title}</h3>
    {children}
  </Flex>
);

export const StockOrders = ({ event }: StockOrdersProps) => {
  const mobile = isMobile();
  const [selectedData, setSelectedData] = useState<
    StockOrder | StockTransfer
  >();

  const { data, ...props } = useStockOrdersInfoQuery(String(event.id), {
    skip: !event.id,
  });

  return (
    <Details {...mapQuery(props)}>
      <Flex gap={16} flexDirection={mobile ? 'column' : 'row'}>
        <Card title="Stock Orders">
          <Styles.StyledList
            count={data?.stockOrders?.length}
            mainWrapperStyles={{ padding: 0 }}
            header={<ListHeader padding="8px" titles={stockOrdersTitles} />}
          >
            {data?.stockOrders?.map((value) => (
              <ListCard
                key={value.id}
                headers={stockOrdersTitles}
                onClick={() => setSelectedData(value)}
                values={[
                  formatDate(value.date),
                  value.supplier.name,
                  formatPrice(value.amount),
                  formatPrice(value.ltlPrice),
                ]}
              />
            ))}
          </Styles.StyledList>
        </Card>

        <Card title="Stock Transfers">
          <Styles.StyledList
            mainWrapperStyles={{ padding: 0 }}
            count={data?.stockTransfers?.length}
            header={<ListHeader padding="8px" titles={transferTitles} />}
          >
            {data?.stockTransfers?.map((value) => (
              <ListCard
                key={value.id}
                headers={transferTitles}
                onClick={() => setSelectedData(value)}
                values={[
                  formatDate(value.date),
                  value.sourceStorage?.name,
                  formatPrice(value.wholesaleCost),
                  value.createdBy.name,
                ]}
              />
            ))}
          </Styles.StyledList>
        </Card>
      </Flex>

      {selectedData && (
        <Dialog
          open
          showCloseButton
          title="Stock Transfer Details"
          width={mobile ? '90vw' : '600px'}
          onClose={() => setSelectedData(undefined)}
        >
          {() =>
            isStockTransfer(selectedData) ? (
              <StockTransferDetails isOnlyRead data={selectedData} />
            ) : (
              <StockOrderDetails isOnlyRead data={selectedData} />
            )
          }
        </Dialog>
      )}
    </Details>
  );
};
