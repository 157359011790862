import { AppRoutes } from 'config';
import { useHeader } from 'context';
import { mapQuery } from 'utils/query';
import { useSearchPagination } from 'hooks';
import { useListQuery } from 'store/api/customers';
import { List, ListHeader, NewEntityButton } from 'components';

import { Card } from './card';
import { titles } from './config';
import { ListPageProps } from './types';

export const ListPage = ({
  pageName,
  isEmployee,
  employeeId,
  headerPadding,
  getDetailsPath,
  hideCreateButton,
  ...restProps
}: ListPageProps) => {
  const pageData = useSearchPagination();
  const { currentPage: page, debounceQuery: search } = pageData;

  const { data, ...props } = useListQuery({
    page,
    search,
    employeeId,
  });

  useHeader(
    isEmployee
      ? { pageName: 'Employee', showBackButton: true }
      : { pageName: pageName || 'Customers' }
  );

  return (
    <List
      {...mapQuery(props)}
      {...pageData}
      count={data?.count}
      testId="customer-list"
      mainWrapperStyles={{ paddingBottom: '16px' }}
      header={<ListHeader titles={titles} padding={headerPadding} />}
      searchBarRightSlot={
        !hideCreateButton ? (
          <NewEntityButton text="New customer" path={AppRoutes.Customers.New} />
        ) : undefined
      }
      {...restProps}
    >
      {data?.rows?.map((item) => (
        <Card data={item} key={item.id} getDetailsPath={getDetailsPath} />
      ))}
    </List>
  );
};
