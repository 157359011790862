import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { User, UserTypes } from 'types';
import { addSearchParams } from 'utils/query';
import { calculateOffset } from 'utils/helpers';
import { filterEmptyFields } from 'utils/object';
import {
  cacheByIdArg,
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import {
  UserData,
  RoleData,
  UsersRequest,
  UsersResponse,
  UserFormValues,
  CreateApprovalPasswordRequest,
  UpdateApprovalPasswordRequest,
} from './types';

const tagType = 'Users';

const handleFormData = (data: Partial<UserFormValues>) => {
  const resultData = { ...data };

  if (resultData.role && UserTypes.ops.includes(resultData.role)) {
    delete resultData.companyId;
  }

  return resultData;
};

export const usersApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'usersApi',
  endpoints: (build) => ({
    listRoles: build.query<RoleData[], void>({
      providesTags: [tagType],
      query: () => ({
        method: 'get',
        url: URL.USER_ROLES,
      }),
    }),
    get: build.query<UserData, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.USER_DETAILS.replace(':id', id),
      }),
    }),
    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.USER_DETAILS.replace(':id', id),
      }),
    }),
    create: build.mutation<void, UserFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => {
        return {
          method: 'post',
          url: URL.USERS,
          data: filterEmptyFields(handleFormData(data)),
        };
      },
    }),
    updateApprovalPassword: build.mutation<User, UpdateApprovalPasswordRequest>(
      {
        invalidatesTags: invalidatesList(tagType),
        query: ({ id, ...data }) => ({
          data,
          method: 'patch',
          url: URL.USER_APPROVAL_PASSWORD.replace(':id', String(id)),
        }),
      }
    ),
    createApprovalPassword: build.mutation<User, CreateApprovalPasswordRequest>(
      {
        invalidatesTags: invalidatesList(tagType),
        query: ({ id, approvalPassword }) => ({
          method: 'post',
          data: { approvalPassword },
          url: URL.USER_APPROVAL_PASSWORD.replace(':id', String(id)),
        }),
      }
    ),
    edit: build.mutation<void, UserFormValues>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => {
        return {
          method: 'patch',
          url: URL.USER_DETAILS.replace(':id', id),
          data: filterEmptyFields({
            ...handleFormData(data),
            id,
          }),
        };
      },
    }),
    list: build.query<UsersResponse, UsersRequest | void>({
      providesTags: (result) => providesList(tagType)(result?.rows),
      query: (params) => {
        const { page = 1, limit = 20, ...otherParams } = params || {};
        const offset = calculateOffset(page, limit);
        const searchParams = {
          limit,
          offset,
          ...otherParams,
        };

        return {
          method: 'get',
          url: addSearchParams(URL.USERS, searchParams),
        };
      },
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useEditMutation,
  useListRolesQuery,
  useRemoveMutation,
  useCreateMutation,
  useCreateApprovalPasswordMutation,
  useUpdateApprovalPasswordMutation,
} = usersApi;

export type { UserData, RoleData, UserFormValues };
