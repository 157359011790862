import { CSSProperties, PropsWithChildren } from 'react';
import { FieldArray, FormikValues, useFormikContext } from 'formik';

import { Flex } from 'components/flex';
import { ButtonStyleTypes } from 'types';
import { Row, Col } from 'components/grid';
import { Button } from 'components/button';
import { SelectOption } from 'components/select';
import { InfoBlock } from 'components/info-block';
import * as CommonStyles from 'components/styles';
import { Shipment } from 'store/api/customers/types';
import { SectionHeader } from 'components/section-header';
import { useListQuery as useUsersQuery } from 'store/api/users';
import { useListQuery as useEventsQuery } from 'store/api/event';
import { FormPhotoFieldRow } from 'components/form-photo-field-row';
import { AddButton, DeleteButton } from 'components/specified-buttons';
import {
  InputField,
  SelectField,
  DatePickerField,
  StatesSelectField,
  AddressAutoCompleteInputField,
} from 'components/fields';

import { CustomerFormValues } from './types';
import {
  customerValidationSchema,
  getCustomerFormInitialValues,
} from './config';

export interface CustomerFormFieldsProps extends PropsWithChildren {
  fieldNamesPrefix?: string;
  formInnerStyle?: CSSProperties;
  employeeOptions?: SelectOption[];
}

export const CustomerFormFields = ({
  formInnerStyle,
  employeeOptions,
  fieldNamesPrefix,
}: CustomerFormFieldsProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const { data: users } = useUsersQuery({ limit: 10000 });
  const { data: events } = useEventsQuery({ limit: 10000, status: 'accepted' });

  const getFieldName = (name: string) =>
    fieldNamesPrefix ? `${fieldNamesPrefix}.${name}` : name;

  const formShipments: Shipment[] = fieldNamesPrefix
    ? values[fieldNamesPrefix].shipments
    : values.shipments;

  const stateFieldName = getFieldName('state');
  const zipFieldName = getFieldName('zip');
  const cityFieldName = getFieldName('city');

  return (
    <CommonStyles.FormInner style={formInnerStyle}>
      <FormPhotoFieldRow />

      <SectionHeader withBorder={false} title="General info" />
      <Row>
        <Col col={3}>
          <InputField
            label="First name"
            placeholder="First name"
            name={getFieldName('firstName')}
          />
        </Col>
        <Col col={3}>
          <InputField
            label="Last name"
            placeholder="Last name"
            name={getFieldName('lastName')}
          />
        </Col>
        <Col col={3}>
          <InputField
            type="tel"
            label="Phone Number"
            placeholder="Phone Number"
            name={getFieldName('phone')}
          />
        </Col>
      </Row>
      <Row>
        <Col col={3}>
          <InputField
            type="email"
            label="Email"
            placeholder="Email"
            name={getFieldName('email')}
          />
        </Col>
        <Col col={3}>
          <DatePickerField
            label="Birthday"
            maxDate={new Date()}
            name={getFieldName('birthday')}
          />
        </Col>
      </Row>
      <Row>
        <Col col={3}>
          <AddressAutoCompleteInputField
            zipFieldName={zipFieldName}
            cityFieldName={cityFieldName}
            name={getFieldName('address')}
            stateFieldName={stateFieldName}
          />
        </Col>
        <Col col={2}>
          <StatesSelectField isDisabled name={stateFieldName} />
        </Col>
        <Col col={2}>
          <InputField
            number
            disabled
            label="Zip Code"
            name={zipFieldName}
            placeholder="Zip Code"
          />
        </Col>
        <Col col={2}>
          <InputField
            disabled
            label="City"
            placeholder="City"
            name={cityFieldName}
          />
        </Col>
      </Row>

      <SectionHeader withBorder={false} title="Shipment info" />

      <FieldArray
        name={getFieldName('shipments')}
        render={({ push, remove }) => (
          <Flex column gap={16}>
            {formShipments.map((shipment, index) => (
              <InfoBlock key={index}>
                <Row>
                  <Col>
                    <Button
                      text="Use customer info"
                      styleType={ButtonStyleTypes.Outline}
                      onClick={async () => {
                        const currentValues = fieldNamesPrefix
                          ? values[fieldNamesPrefix]
                          : values;

                        await setFieldValue(
                          `${getFieldName('shipments')}[${index}].address`,
                          currentValues.address
                        );
                        await setFieldValue(
                          `${getFieldName('shipments')}[${index}].state`,
                          currentValues.state
                        );
                        await setFieldValue(
                          `${getFieldName('shipments')}[${index}].zip`,
                          currentValues.zip
                        );
                        await setFieldValue(
                          `${getFieldName('shipments')}[${index}].city`,
                          currentValues.city
                        );
                        await setFieldValue(
                          `${getFieldName('shipments')}[${index}].phone`,
                          currentValues.phone
                        );
                        await setFieldValue(
                          `${getFieldName('shipments')}[${index}].email`,
                          currentValues.email
                        );
                        await setFieldValue(
                          `${getFieldName('shipments')}[${index}].contactName`,
                          currentValues.firstName || currentValues.lastName
                            ? `${currentValues.firstName ? `${currentValues.firstName} ` : ''}${
                                currentValues.lastName
                                  ? currentValues.lastName
                                  : ''
                              }`
                            : ''
                        );
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col col={3}>
                    <AddressAutoCompleteInputField
                      label="Address"
                      placeholder="Address"
                      name={`${getFieldName('shipments')}[${index}].address`}
                      zipFieldName={`${getFieldName('shipments')}[${index}].zip`}
                      cityFieldName={`${getFieldName('shipments')}[${index}].city`}
                      stateFieldName={`${getFieldName('shipments')}[${index}].state`}
                    />
                  </Col>
                  <Col col={2}>
                    <StatesSelectField
                      isDisabled
                      name={`${getFieldName('shipments')}[${index}].state`}
                    />
                  </Col>
                  <Col col={2}>
                    <InputField
                      number
                      disabled
                      label="Zip Code"
                      placeholder="Zip Code"
                      name={`${getFieldName('shipments')}[${index}].zip`}
                    />
                  </Col>
                  <Col col={2}>
                    <InputField
                      disabled
                      label="City"
                      placeholder="City"
                      name={`${getFieldName('shipments')}[${index}].city`}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col col={3}>
                    <InputField
                      label="Contact Name"
                      placeholder="Contact Name"
                      name={`${getFieldName('shipments')}[${index}].contactName`}
                    />
                  </Col>
                  <Col col={3}>
                    <InputField
                      type="tel"
                      label="Phone"
                      placeholder="Phone"
                      name={`${getFieldName('shipments')}[${index}].phone`}
                    />
                  </Col>
                  <Col col={3}>
                    <InputField
                      type="tel"
                      label="Emergency Phone"
                      placeholder="Emergency Phone"
                      name={`${getFieldName('shipments')}[${index}].emergencyPhone`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col col={3}>
                    <InputField
                      type="email"
                      label="Email"
                      placeholder="Email"
                      name={`${getFieldName('shipments')}[${index}].email`}
                    />
                  </Col>
                </Row>

                {formShipments.length > 1 && (
                  <DeleteButton text="Remove" onClick={() => remove(index)} />
                )}
              </InfoBlock>
            ))}
            <AddButton onClick={() => push({})} text="Add shipment address" />
          </Flex>
        )}
      />

      <SectionHeader withBorder={false} title="Registered info" />
      <Row>
        <Col col={3}>
          <SelectField
            onlyValue
            label="Employee"
            name={getFieldName('employeeId')}
            options={
              employeeOptions ||
              (users?.rows?.map(({ id, name }) => ({
                label: name,
                value: String(id),
              })) ??
                [])
            }
          />
        </Col>
      </Row>
      <Row>
        <Col col={3}>
          <DatePickerField
            label="Started"
            maxDate={new Date()}
            name={getFieldName('registered')}
          />
        </Col>
        <Col col={3}>
          <SelectField
            onlyValue
            label="Event"
            menuPlacement="top"
            name={getFieldName('eventId')}
            options={
              events?.rows?.map((event) => ({
                label: event.name,
                value: String(event.id),
              })) ?? []
            }
          />
        </Col>
      </Row>
    </CommonStyles.FormInner>
  );
};

export type { CustomerFormValues };

export { customerValidationSchema, getCustomerFormInitialValues };
