import { useShowMore } from 'hooks';
import { formatTimezoneSpecific } from 'utils/date';
import { AnalyticsEmployee } from 'store/api/analytics';
import { joinStrings, formatPrice } from 'utils/helpers';
import { TemplateList } from 'components/transactions-list';

import { TodayEmployeesListProps } from './types';

export const employeeConfig = {
  template: 'repeat(5, 1fr)',
  headers: ['Employee', 'Locations', 'Net amount', 'Sales QTY', 'Last Sales'],
  cellsMobile: [
    { row: '1/2', col: '1/3' },
    { row: '2/2', col: '1/3' },
    { row: '3/4', col: '1/2' },
    { row: '3/4', col: '2/3' },
  ],
};

export const TodayEmployeesList = ({
  data,
  title,
  setEmployee,
  timezoneCode,
}: TodayEmployeesListProps) => {
  const { visibleData, ...showMoreProps } = useShowMore(data);

  return (
    <TemplateList<AnalyticsEmployee>
      {...employeeConfig}
      data={visibleData}
      hasMinHeight={false}
      listProps={showMoreProps}
      title={title || 'Today employees'}
      tabletTemplate={employeeConfig.template}
      maxHeight={visibleData?.length ? 350 : undefined}
      onClick={setEmployee ? (e, value) => setEmployee(value) : undefined}
      render={(employeeInfo) => [
        employeeInfo.user.name,
        joinStrings(employeeInfo?.eventNames, ', '),
        formatPrice(employeeInfo.netSales),
        employeeInfo.totalCheckouts,
        formatTimezoneSpecific(
          employeeInfo.lastSale.createdAt,
          timezoneCode || employeeInfo.lastSale?.event?.timezone?.code,
          'LT'
        ),
      ]}
    />
  );
};
