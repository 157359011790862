import * as Yup from 'yup';

import { FormValues, ExpensesType } from './types';

export const getInitialValues = (
  eventId?: number,
  isOnlyCashLocation?: boolean
): FormValues => ({
  eventId,
  date: '',
  notes: '',
  amount: '',
  employeeId: '',
  isCashFromEvent: isOnlyCashLocation || false,
  type: isOnlyCashLocation ? ExpensesType.Bonus : undefined,
});

export const getValidationSchema = (isOnlyCashLocation?: boolean) => {
  const typeValidation = isOnlyCashLocation
    ? Yup.string().required()
    : Yup.string();

  return Yup.object().shape({
    type: typeValidation,
    date: Yup.date().required(),
    isCashFromEvent: Yup.boolean(),
    eventId: Yup.number().required(),
    employeeId: Yup.number().required(),
    amount: Yup.number().min(1).required(),
    notes: Yup.string().min(5).max(200).required(),
  });
};
