import { useParams } from 'react-router-dom';

import { Messages } from 'config';
import { useAppSelector } from 'store';
import { capitalize } from 'utils/string';
import { isMobile, handleMutation } from 'hooks';
import { PayoutType, ButtonColors } from 'types';
import { useDetailsQuery } from 'store/api/event';
import { selectAuthUser } from 'store/slices/auth';
import { useCreateMutation } from 'store/api/payout';
import {
  Row,
  Col,
  Dialog,
  Button,
  DialogType,
  ButtonTypes,
  SelectField,
  PaymentsForm,
  CheckboxField,
  RadioFieldGroup,
  NewEntityButton,
} from 'components';

import { getInitialValues, getValidationSchema } from './config';
import {
  FormValues,
  ExpensesType,
  CreateBonusAdvanceDialogProps,
} from './types';

export const CreateBonusAdvanceDialog = ({
  eventId,
  isAdvances,
  customTitle,
  isOnlyCashLocation,
}: CreateBonusAdvanceDialogProps) => {
  const mobile = isMobile();
  const { id } = useParams();
  const [create, mutation] = useCreateMutation();
  const authUser = useAppSelector(selectAuthUser);
  const { data, isFetching } = useDetailsQuery(id!, { skip: !id });

  const title = isAdvances ? 'advance' : 'bonus';
  handleMutation({
    ...mutation,
    successMessage: `${title} ${Messages.ADDED_SUCCESSFULLY}`,
  });

  const employeeOptions = [data?.leader, ...(data?.teamMembers || [])]
    ?.filter((value) => value && value?.id !== authUser?.id)
    .map((value) => ({
      label: String(value?.name),
      value: String(value?.employee?.id),
    }));

  return (
    <Dialog
      title={customTitle || `Create ${title}`}
      trigger={<NewEntityButton text={customTitle || `Add ${title}`} />}
    >
      {(close, _, ButtonWrap) => (
        <PaymentsForm<FormValues>
          isDialog
          isTopChildren
          hideBackButton
          isDisableEvent
          validationSchema={getValidationSchema(isOnlyCashLocation)}
          initialValues={getInitialValues(eventId, isOnlyCashLocation)}
          footerButtons={({ dirty, isSubmitting }) => (
            <ButtonWrap type={DialogType.Plain}>
              <Button
                text="Cancel"
                onClick={close}
                fullWidth={mobile}
                colorType={ButtonColors.LightBlue}
              />
              <Button
                text="Add"
                fullWidth={mobile}
                type={ButtonTypes.Submit}
                disabled={!dirty || isSubmitting}
              />
            </ButtonWrap>
          )}
          onSubmit={async (values) => {
            let type: PayoutType = values?.isCashFromEvent
              ? PayoutType.PrepaidBonus
              : PayoutType.Bonus;

            if (isAdvances || values.type === ExpensesType.Advance) {
              type = values?.isCashFromEvent
                ? PayoutType.PrepaidAdvance
                : PayoutType.Advance;
            }

            const response = await create({
              ...values,
              type,
              amount: +values.amount,
              employeeId: +values.employeeId,
            }).unwrap();

            if (response.id) {
              close();
            }
          }}
        >
          {isOnlyCashLocation && (
            <Row>
              <Col>
                <RadioFieldGroup
                  name="type"
                  transparent
                  padding="0"
                  label="Select Type"
                  items={Object.values(ExpensesType).map((value) => ({
                    value,
                    label: capitalize(value),
                  }))}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <SelectField
                onlyValue
                label="Employee"
                name="employeeId"
                isDisabled={isFetching}
                options={employeeOptions}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <CheckboxField
                name="isCashFromEvent"
                label="Cash From Location"
                disabled={isOnlyCashLocation}
              />
            </Col>
          </Row>
        </PaymentsForm>
      )}
    </Dialog>
  );
};
