import { ListCardStyle } from 'types';
import { formatPrice } from 'utils/helpers';
import { EventReport, EventProfitAndLoss } from 'store/api/event';

export const revenueTitles = ['Date', 'Check', 'Cash', 'Net Credit', 'Tip'];
export const expensesTitles = [
  'Notes',
  'Date',
  'Amount',
  'Type',
  'Employee',
  '',
];
export const expensesTemplate = 'repeat(5, 1fr) 24px';

export const commonProps = {
  hasMinHeight: true,
  mainWrapperStyles: { padding: 0 },
};

export const calculatePaymentMethods = (eventReports: EventReport[]) =>
  eventReports.reduce(
    (acc, report) => {
      for (const [method, amount] of Object.entries(report.paymentMethods)) {
        acc[method] += amount;
      }
      return acc;
    },
    { cash: 0, check: 0, credit: 0 } as Record<string, any>
  );

export const commonFooterCardProps = {
  isOdd: true,
  selected: true,
  odd: ListCardStyle.Default,
};

export const getExpensesInfo = (data?: EventProfitAndLoss) => [
  { title: 'Commissions', value: data?.totalCommissions },
  { title: 'Tax', value: data?.totalTax },
  { title: 'Processing Fee', value: data?.totalProcessingFees },
  { title: 'Product Cost', value: data?.totalProductSoldCost },
];

export const getSummaryInfo = (data?: EventProfitAndLoss) => [
  { title: 'Profit', value: formatPrice(data?.profit) },
  {
    title: 'Profit Percentage',
    value: data?.profitPercentage && `${data?.profitPercentage}%`,
  },
  {
    title: 'PRP',
    value:
      data?.productRevenuePercentage && `${data?.productRevenuePercentage}%`,
  },
  { title: 'Total Net Sales', value: formatPrice(data?.totalNetSales) },
  { title: 'Working Days', value: data?.workingDays },
];

export const revenueCellsTablet = [
  { row: '1/2', col: '1/3' },
  { row: '1/2', col: '3/4' },
  { row: '2/3', col: '1/2' },
  { row: '2/3', col: '2/3' },
  { row: '2/3', col: '3/4' },
];
