export const startTitles = ['Product', 'Masters', 'Singles', 'Total'];
export const soldTitles = [
  'Product',
  'Revenue',
  'Quantity',
  'Cost Price',
  'Total Stock Cost',
];
export const endTitles = [
  'Product',
  'Masters',
  'Singles',
  'Demos',
  'Damaged',
  'Total',
];
