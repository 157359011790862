import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;
  &:focus-visible {
    outline: unset;
  }
`;

export const Input = styled.input`
  top: -50px;
  left: -50px;
`;
