import { Fragment } from 'react';

import { Info } from 'components/info';
import { EmptyPlaceholder } from 'config';
import { Avatar } from 'components/avatar';
import { Row, Col } from 'components/grid';
import { PageHeader } from 'components/page-header';
import { SectionHeader } from 'components/section-header';

import * as Styles from './styles';
import {
  GeneralCol,
  GeneralColInfo,
  GeneralInfoProps,
  GeneralColChildren,
} from './types';

export const GeneralInfo = ({
  name,
  photo,
  onEdit,
  header,
  subtitle,
  sections,
  subHeader,
  rightSlot,
  className,
  topOffset,
  avatarIcon,
  placeholder,
  withoutPhoto,
  photoSize = 64,
  avatarUserName,
  containerStyles,
  withHeader = true,
  disableSticky = false,
  stickyWithHeader = true,
  withPlaceholders = false,
  infoPlaceholder = EmptyPlaceholder,
  sectionWrapper: SectionWrapper = Fragment,
  ...props
}: GeneralInfoProps) => {
  return (
    <Styles.Container className={className} style={containerStyles}>
      {withHeader && (
        <PageHeader
          {...props}
          name={name}
          onEdit={onEdit}
          footer={header}
          subtitle={subtitle}
          rightSlot={rightSlot}
          topOffset={topOffset}
          disableSticky={disableSticky}
          stickyWithHeader={stickyWithHeader}
          header={
            withoutPhoto ? undefined : (
              <Avatar
                url={photo}
                size={photoSize}
                icon={avatarIcon}
                placeholder={placeholder}
                userName={name ?? avatarUserName}
              />
            )
          }
        />
      )}

      {subHeader}

      {sections.map(
        ({ rows, children, hideSection, ...sectionProps }, index) => {
          const hasSomeInfo =
            !!children ||
            rows?.some((cols) =>
              cols?.some(
                (col) =>
                  ('value' in col && col.value) ||
                  ('children' in col && col.children)
              )
            );

          if ((!hasSomeInfo && !withPlaceholders) || hideSection) {
            return;
          }

          return (
            <SectionWrapper key={index}>
              {sectionProps.title && <SectionHeader {...sectionProps} />}
              {children}
              {rows &&
                rows.map((cols, rowIndex) => (
                  <Row key={`row_${rowIndex}`}>
                    {cols.map(
                      (
                        {
                          // @ts-ignore
                          value,
                          // @ts-ignore
                          title,
                          innerRef,
                          children: colChildren,
                          ...col
                        },
                        colIndex
                      ) => {
                        if (colChildren) {
                          return (
                            <Col
                              innerRef={innerRef}
                              key={`col_${colIndex}`}
                              {...col}
                            >
                              {colChildren}
                            </Col>
                          );
                        } else if ((withPlaceholders && title) || value) {
                          return (
                            <Col
                              innerRef={innerRef}
                              key={`col_${colIndex}`}
                              {...col}
                            >
                              <Info
                                title={title}
                                value={value ?? infoPlaceholder}
                                {...col}
                              />
                            </Col>
                          );
                        }
                      }
                    )}
                  </Row>
                ))}
            </SectionWrapper>
          );
        }
      )}
    </Styles.Container>
  );
};

export type {
  GeneralCol,
  GeneralColInfo,
  GeneralInfoProps,
  GeneralColChildren,
};
