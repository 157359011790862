import styled from 'styled-components';

import { flexCenter } from 'styles';
import { EventInventory } from 'types';

export const Title = styled.div<{ title: EventInventory }>`
  ${flexCenter};
  width: 100%;
  padding: 4px 0;
  border-radius: 16px;
  background-color: ${({ theme, title }) =>
    theme.config.eventInventory[title].background};
`;
