import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useHeader } from 'context';
import { mapQuery } from 'utils/query';
import { useAppSelector } from 'store';
import { selectAuthUser } from 'store/slices/auth';
import { states, Messages, AppRoutes } from 'config';
import { EventPayment } from 'store/api/event/types';
import { formatDate, formatTimeData } from 'utils/date';
import { useTitle, useNavigate, useOnceLoader } from 'hooks';
import { formatPhone, addPercentageLabel } from 'utils/helpers';
import {
  Role,
  Roles,
  EventTypeTitle,
  BackOfficeRoles,
  EventLocationTypeTitle,
} from 'types';
import {
  List,
  Link,
  Rating,
  Details,
  ListCard,
  InfoBlock,
  ListHeader,
  GeneralInfo,
  DeleteDialog,
} from 'components';
import {
  EventLocation,
  useDetailsQuery,
  useRemoveMutation,
  eventPaymentTypeLabel,
  eventPaymentTransactionLabel,
} from 'store/api/event';

import { CreateReportDialog } from './report';

const getLocationHeader = (isHideForSalesAndManager?: boolean) => {
  const titles = ['#', 'Size', 'Location'];

  if (!isHideForSalesAndManager) {
    titles.splice(1, 0, '$');
  }

  return titles;
};

const LocationListCard = ({
  titles,
  location,
  isHideForSalesAndManager,
}: {
  titles?: string[];
  location: EventLocation;
  isHideForSalesAndManager?: boolean;
}) => {
  const values = [
    location.number,
    location?.length && location?.width
      ? `${location.length}x${location.width}ft`
      : '',
    location.name,
  ];

  if (!isHideForSalesAndManager) {
    values.splice(1, 0, location?.income ? String(location.income) : '');
  }

  return <ListCard values={values} headers={titles} />;
};

const PaymentListCard = ({ data }: { data: EventPayment }) => {
  return (
    <ListCard
      values={[
        eventPaymentTypeLabel[data.type],
        data.transaction && eventPaymentTransactionLabel[data.transaction],
        data.notes,
        data.amount,
        data.date && formatDate(data.date),
        data.paidBy?.name,
        data.paymentMethod?.name,
        data.cashed ? 'Yes' : 'No',
      ]}
    />
  );
};

export const DetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);

  const authUser = useAppSelector(selectAuthUser);
  const authUserRole = authUser?.role?.name;
  const isUserTypeOps = authUser?.userType === 'ops';
  const isManager = authUserRole === BackOfficeRoles.MANAGER;
  const isSalesRep = authUserRole === BackOfficeRoles.SALES_REP;
  const { data, ...props } = useDetailsQuery(id!, { skip: !id });
  const [remove, removeMutation] = useRemoveMutation();
  const isDisplayReport =
    ([Roles.SITE_ADMIN, Roles.COMPANY_OWNER] as Role[]).includes(
      authUserRole!
    ) || data?.leaderId === authUser?.id;

  useTitle('Event', data?.name ?? '');

  useHeader({ pageName: 'Event', showBackButton: true });

  const { isLoading } = useOnceLoader(props);

  const isHideForSalesAndManager = isSalesRep || isManager;

  const locationTitles = getLocationHeader(isHideForSalesAndManager);

  return (
    <Details {...mapQuery(props)} isLoading={isLoading}>
      {data && (
        <GeneralInfo
          photoSize={128}
          withPlaceholders
          name={data.name}
          withBorder={false}
          photo={data.photoUrl}
          stickyWithHeader={false}
          sectionWrapper={InfoBlock}
          containerStyles={{ gap: '24px' }}
          onEdit={isHideForSalesAndManager ? undefined : () => navigate('edit')}
          titleRightSlot={
            isDisplayReport ? <CreateReportDialog event={data} /> : undefined
          }
          rightSlot={
            isHideForSalesAndManager ? undefined : (
              <DeleteDialog
                id={id!}
                action={remove}
                title="Delete event"
                name={data?.name ?? ''}
                mutation={removeMutation}
                open={isOpenDeleteDialog}
                navigateUrl={AppRoutes.Events.Home}
                onClose={() => setOpenDeleteDialog(false)}
                successMessage={`Event ${Messages.DELETED_SUCCESSFULLY}`}
              />
            )
          }
          sections={[
            {
              withBorder: false,
              title: 'General information',
              rows: [
                [
                  {
                    col: 3,
                    title: 'ID',
                    colTablet: 6,
                    colMobile: 3,
                    value: data.id,
                  },
                  {
                    col: 3,
                    colTablet: 6,
                    colMobile: 9,
                    title: 'Between dates',
                    value:
                      data.startDate && data.endDate
                        ? `${formatDate(dayjs(data.startDate))} - ${formatDate(dayjs(data.endDate))}`
                        : '',
                  },
                  {
                    col: 3,
                    colTablet: 6,
                    title: 'Selling Days',
                    value: data.sellingDays,
                  },
                  {
                    col: 3,
                    colTablet: 6,
                    title: 'Address',
                    value: data.address,
                  },
                ],
                [
                  {
                    col: 3,
                    title: 'Tax',
                    colTablet: 6,
                    colMobile: 6,
                    value: data.tax ? addPercentageLabel(data.tax) : undefined,
                  },
                  {
                    col: 3,
                    colTablet: 6,
                    colMobile: 6,
                    title: 'State',
                    value: data.state && states[data.state].name,
                  },
                  {
                    col: 3,
                    colTablet: 6,
                    colMobile: 6,
                    title: 'City',
                    value: data.city,
                  },
                  {
                    col: 3,
                    title: 'ZIP',
                    colTablet: 6,
                    colMobile: 6,
                    value: data.zip,
                  },
                ],
                [
                  {
                    col: 3,
                    colTablet: 6,
                    title: 'Timezone',
                    value: data?.timezoneId ? data.timezone?.code : undefined,
                  },
                ],
              ],
            },
            ...(Object.values(data.schedule ?? {}).length
              ? [
                  {
                    title: 'Schedule',
                    withBorder: false,
                    style: { paddingTop: 0 },
                    rows: [
                      [
                        ...data.schedule.map(({ date, time, dayOff }) => ({
                          col: 2,
                          colTablet: 4,
                          title: formatDate(date, 'L ddd'),
                          value: dayOff
                            ? 'Day off'
                            : `${formatTimeData(time?.from ?? 0)} - ${formatTimeData(time?.to ?? 0)}`,
                        })),
                      ],
                    ],
                  },
                ]
              : []),
            ...(!isHideForSalesAndManager
              ? [
                  {
                    withBorder: false,
                    title: 'Contact info',
                    style: { paddingTop: 0 },
                    rows: [
                      [
                        {
                          col: 4,
                          colTablet: 6,
                          title: 'Contact Name',
                          value: data.contactName,
                        },
                        {
                          col: 4,
                          colTablet: 6,
                          title: 'Phone',
                          value: formatPhone(data.contactPhone),
                        },
                        {
                          col: 4,
                          colTablet: 6,
                          title: 'Email',
                          value: data.contactEmail,
                        },
                      ],
                    ],
                  },
                ]
              : []),
            {
              withBorder: false,
              style: { paddingTop: 0 },
              title: 'Event theme & Products',
              rows: [
                [
                  {
                    col: 4,
                    colTablet: 4,
                    title: 'Theme',
                    value: data.theme?.name,
                  },
                  { col: 4, colTablet: 4, title: 'Link', value: data.link },
                  {
                    col: 4,
                    colTablet: 4,
                    title: 'Product',
                    value: data.product?.name,
                  },
                ],
              ],
            },
            ...(!isHideForSalesAndManager
              ? [
                  {
                    withBorder: false,
                    title: 'Attendance',
                    style: { paddingTop: 0 },
                    rows: [
                      [
                        {
                          col: 4,
                          colTablet: 6,
                          title: 'Attendance',
                          value: data.attendance,
                        },
                        {
                          col: 4,
                          colTablet: 6,
                          title: 'Vendors',
                          value: data.vendors,
                        },
                      ],
                      [
                        {
                          col: 4,
                          colTablet: 6,
                          title: 'Event Rating',
                          value: <Rating readonly initialValue={data.rating} />,
                        },
                        {
                          col: 4,
                          colTablet: 6,
                          value: data.ratingMessage,
                          title: 'Event Rating Message',
                        },
                      ],
                    ],
                  },
                ]
              : []),

            {
              rows: [
                [
                  {
                    col: 4,
                    colTablet: 6,
                    title: 'Floor Plan File',
                    value: data.floorPlanFileUrl && (
                      <Link download url={data.floorPlanFileUrl}>
                        Download
                      </Link>
                    ),
                  },
                  {
                    col: 4,
                    colTablet: 6,
                    title: 'Floor Plan Link',
                    value: data.floorPlanLink,
                  },
                  {
                    col: 4,
                    colTablet: 6,
                    title: 'Final Price',
                    value: data.finalPrice,
                    hidden: isHideForSalesAndManager,
                  },
                ],
                [
                  isUserTypeOps
                    ? {
                        col: 4,
                        colTablet: 6,
                        title: 'Booking Team Notes',
                        value: data.bookingTeamNotes,
                      }
                    : {},
                  {
                    col: 4,
                    colTablet: 6,
                    title: 'Notes for client',
                    value: data.notesForClient,
                    hidden: isHideForSalesAndManager,
                  },
                ],
              ],
            },
            {
              withBorder: false,
              style: { paddingTop: 0 },
              title: 'Location Type Info',
              rows: [
                [
                  {
                    col: 4,
                    colTablet: 6,
                    colMobile: 6,
                    title: 'Location Type',
                    value: data.locationType
                      ? EventLocationTypeTitle[data.locationType]
                      : undefined,
                  },
                  {
                    col: 4,
                    colTablet: 6,
                    colMobile: 6,
                    title: 'Event Type',
                    value: data.eventType
                      ? EventTypeTitle[data.eventType]
                      : undefined,
                  },
                ],
              ],
            },
            ...(data.locations.length
              ? [
                  {
                    withBorder: false,
                    title: 'Locations',
                    style: { paddingTop: 0 },
                    children: (
                      <List
                        count={data.locations?.length}
                        header={<ListHeader titles={locationTitles} />}
                      >
                        {data.locations?.map((location) => (
                          <LocationListCard
                            key={location.id}
                            location={location}
                            titles={locationTitles}
                            isHideForSalesAndManager={isHideForSalesAndManager}
                          />
                        ))}
                      </List>
                    ),
                  },
                ]
              : []),
            ...(data.selectedLocations?.length
              ? [
                  {
                    withBorder: false,
                    style: { paddingTop: 0 },
                    title: 'Selected locations',
                    children: (
                      <List
                        count={data.selectedLocations?.length}
                        header={<ListHeader titles={locationTitles} />}
                      >
                        {data.selectedLocations?.map((selectedLocationId) => {
                          const location = data.locations.find(
                            (item) => item.id === selectedLocationId
                          );

                          if (!location) {
                            return null;
                          }

                          return (
                            <LocationListCard
                              key={location.id}
                              location={location}
                              titles={locationTitles}
                              isHideForSalesAndManager={
                                isHideForSalesAndManager
                              }
                            />
                          );
                        })}
                      </List>
                    ),
                  },
                ]
              : []),
            ...(data.payments.length && !isHideForSalesAndManager
              ? [
                  {
                    withBorder: false,
                    title: 'Payments',
                    style: { paddingTop: 0 },
                    children: (
                      <List
                        count={data.payments?.length}
                        header={
                          <ListHeader
                            titles={[
                              'Payment Type',
                              'Transaction',
                              'Notes',
                              'Amount',
                              'Date',
                              'Paid/Scheduled By',
                              'Payment Method',
                              'Cashed',
                            ]}
                          />
                        }
                      >
                        {data.payments?.map((payment) => (
                          <PaymentListCard data={payment} key={payment.id} />
                        ))}
                      </List>
                    ),
                  },
                ]
              : []),
            ...(data.notes
              ? [
                  {
                    title: 'Notes',
                    withBorder: false,
                    children: data.notes,
                    style: { paddingTop: 0 },
                  },
                ]
              : []),
            {
              title: 'Team',
              withBorder: false,
              style: { paddingTop: 0 },
              rows: [
                [
                  {
                    col: 4,
                    colTablet: 6,
                    value: data.leader?.name,
                    title: 'Manager Assignment',
                  },
                  {
                    col: 4,
                    colTablet: 6,
                    title: 'Team Assignment',
                    value: data.teamMembers?.length
                      ? data.teamMembers.map((user) => user.name).join(', ')
                      : '-',
                  },
                ],
              ],
            },
          ]}
        />
      )}
    </Details>
  );
};
