import { useState } from 'react';

import { useShowMore } from 'hooks';
import { ButtonColors } from 'types';
import { mapQuery } from 'utils/query';
import { formatDate } from 'utils/date';
import { formatPhone, formatPrice } from 'utils/helpers';
import { Icon, List, Button, ListCard, ListHeader } from 'components';
import {
  EventTeamMember,
  useEmployeeCommissionsInfoQuery,
} from 'store/api/event';

import * as Styles from './styles';
import { CommissionsProps } from './types';
import { CommissionDetailsDialog } from './dialog';
import { employeeConfig, commissionTitles } from './config';

export const Commissions = ({ event }: CommissionsProps) => {
  const eventEmployees = [...(event.teamMembers || []), event.leader!];
  const [selectedEmployee, setSelectedEmploye] = useState<EventTeamMember>();

  const { data, ...props } = useEmployeeCommissionsInfoQuery(
    { id: event.id, userId: selectedEmployee?.id! },
    { skip: !selectedEmployee }
  );

  const { visibleData, ...showMoreProps } = useShowMore(data?.commissions);

  if (data && selectedEmployee) {
    return (
      <List
        {...mapQuery(props)}
        {...showMoreProps}
        count={visibleData?.length}
        header={
          <>
            <Styles.HeaderButtonsContainer>
              <Button
                transparent
                padding="0"
                icon={<Icon.ChevronLeft />}
                text={selectedEmployee?.name}
                colorType={ButtonColors.BlackWhite}
                onClick={() => setSelectedEmploye(undefined)}
              />

              <CommissionDetailsDialog
                data={data}
                selectedEmployee={selectedEmployee}
              />
            </Styles.HeaderButtonsContainer>

            <ListHeader titles={commissionTitles} />
          </>
        }
      >
        {visibleData?.map((commission, index) => (
          <ListCard
            key={index}
            headers={commissionTitles}
            values={[
              formatDate(commission.date),
              formatPrice(commission.grossSale),
              formatPrice(commission.netSale),
              formatPrice(commission.commissions),
              formatPrice(commission.tip),
              formatPrice(commission.bonuses),
              formatPrice(commission.advances),
            ]}
          />
        ))}
      </List>
    );
  }

  return (
    <List
      count={eventEmployees.length}
      mainWrapperStyles={{ padding: 0 }}
      header={
        <ListHeader
          padding="8px"
          titles={employeeConfig.titles}
          template={employeeConfig.template}
        />
      }
    >
      {eventEmployees?.map((value) => (
        <ListCard
          key={value.id}
          headers={employeeConfig.titles}
          template={employeeConfig.template}
          cellsMobile={employeeConfig.cellsMobile}
          onClick={() => setSelectedEmploye(value)}
          values={[
            value?.name,
            value?.email,
            formatPhone(value.employee?.phoneNumber),
            value?.role?.name,
          ]}
        />
      ))}
    </List>
  );
};
