import { Formik } from 'formik';

import { Messages } from 'config';
import { formatPrice } from 'utils/helpers';
import { PayoutStatus, ButtonColors } from 'types';
import { useChangeStatusMutation } from 'store/api/payout';
import { isMobile, useNavigate, handleMutation } from 'hooks';
import {
  Flex,
  Icon,
  Dialog,
  Button,
  InputField,
  DialogType,
  ButtonTypes,
  CommonStyles,
  ButtonWithRef,
} from 'components';

import { ApprovalPayoutDialogProps } from './types';
import { initialValues, validationSchema } from './config';

export const ApprovalPayoutDialog = ({
  data,
  withNagivate,
}: ApprovalPayoutDialogProps) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const [changeStatus, mutation] = useChangeStatusMutation();

  handleMutation({
    ...mutation,
    successMessage: `Reimbursement status ${Messages.UPDATED_SUCCESSFULLY}`,
  });

  return (
    <Flex gap={16}>
      <Dialog
        title="Approve"
        closeOnDocumentClick={false}
        confirmStyle={ButtonColors.Green}
        trigger={
          <ButtonWithRef
            tooltip="Approve"
            fullWidth={mobile}
            icon={<Icon.CheckCircle />}
            colorType={ButtonColors.LightGreen}
          />
        }
        onConfirm={async () => {
          await changeStatus({
            id: data.id,
            status: PayoutStatus.Approved,
          }).unwrap();
          if (withNagivate) {
            navigate(-1);
          }
        }}
        text={
          <>
            You're going make approve for reimbursement at the event{' '}
            <span className="semibold">{data.event?.name}</span> and with amount{' '}
            <span className="semibold">
              {formatPrice(data.amount, '$0.00')}
            </span>
            . Are you sure you want to do this?
          </>
        }
      />

      <Dialog
        title="Reject reimbursement"
        closeOnDocumentClick={false}
        trigger={
          <ButtonWithRef
            tooltip="Reject"
            fullWidth={mobile}
            colorType={ButtonColors.LightRed}
            icon={<Icon.CloseCircleNotFilled />}
          />
        }
      >
        {(close, _, ButtonWrap) => (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async ({ reasonReject }) => {
              const reimbursement = await changeStatus({
                id: data.id,
                reasonReject,
                status: PayoutStatus.NotApproved,
              });
              if (reimbursement) {
                if (withNagivate) {
                  navigate(-1);
                }
                close();
              }
            }}
          >
            {({ dirty, isSubmitting }) => (
              <CommonStyles.Form gap={24}>
                <InputField
                  type="textarea"
                  controlErrorLabel
                  name="reasonReject"
                  label="Reason reject"
                  placeholder="Add reject reason"
                />

                <ButtonWrap type={DialogType.Plain}>
                  <Button
                    text="Cancel"
                    onClick={close}
                    colorType={ButtonColors.LightBlue}
                  />
                  <Button
                    text="Reject"
                    type={ButtonTypes.Submit}
                    colorType={ButtonColors.LightRed}
                    disabled={!dirty || isSubmitting}
                  />
                </ButtonWrap>
              </CommonStyles.Form>
            )}
          </Formik>
        )}
      </Dialog>
    </Flex>
  );
};
